import React from 'react';
import { useHistory } from 'react-router-dom';
import News from './News';
import Notifications from '../notifications/Notifications';
import './Home.css';

/**
 * Default landing page after logging on. Contains news and notifications.
 *
 * @component
 * @example
 * <Home />
 */
const Home = (props) => {
	const history = useHistory();
	const { isAuthenticated } = props;
	// If user is not authenticated, start on the login page.
	if (!isAuthenticated) {
		history.push('/login');
	}
	return (
		<div id="home">
			<div id="homeContent">
				<News
					numToDisplay={5} // I am setting this as a sane default
				/>
				<Notifications numToDisplay={3} />
			</div>
		</div>
	);
};

export default Home;
