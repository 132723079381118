/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-cond-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-constant-condition */
import * as go from 'gojs';

const formatMapData = (mapData) => {
	/// //////////////////////////////////////////
	// LINKAGE LEVELS FORMATTING INTO NODE ARRAY //
	/// //////////////////////////////////////////
	const formatlinkageLevels = (neighborhoodItem) => {
		if (neighborhoodItem !== undefined) {
			const newlinkageLevelFormat = {};
			newlinkageLevelFormat.id = neighborhoodItem.id * -1;
			newlinkageLevelFormat.name = neighborhoodItem.linkageLevelName;
			newlinkageLevelFormat.nodes = neighborhoodItem.nodes;
			newlinkageLevelFormat.nodeKey = neighborhoodItem.id * -1;
			newlinkageLevelFormat.short_description = neighborhoodItem.short_description;
			newlinkageLevelFormat.long_description = neighborhoodItem.long_description;
			newlinkageLevelFormat.projectId = neighborhoodItem.projectId;
			newlinkageLevelFormat.linkageLevels = neighborhoodItem.linkageLevels;
			newlinkageLevelFormat.dash = neighborhoodItem.dash;
			newlinkageLevelFormat.supers = neighborhoodItem.supers;
			newlinkageLevelFormat.category = 'Super';
			// DESIGN FORMATTING
			newlinkageLevelFormat.color = 'rgba(21,96,183, 0.1)';

			return newlinkageLevelFormat;
		}
	};
	const gojslinkageLevels = mapData.neighborhood.linkageLevels?.map(formatlinkageLevels);
	mapData.neighborhood.nodes.push(...gojslinkageLevels);
	/// ///////////////////
	// NODE FORMATTING //
	/// ///////////////////
	const formatNodes = (nodeItem) => {
		if (nodeItem !== undefined) {
			const formattedNodes = {};
			formattedNodes.key = nodeItem.id;
			formattedNodes.nodeKey = nodeItem.nodeKey;
			formattedNodes.title = nodeItem.name;
			formattedNodes.name = nodeItem.name;
			formattedNodes.description = nodeItem.description;
			formattedNodes.text = nodeItem.name;
			/// //////////////////////////
			// ASSIGNING NEIGHBORHOODS //
			/// //////////////////////////
			if (nodeItem.category === 'Super') {
				formattedNodes.category = nodeItem.category;
				formattedNodes.color = nodeItem.color;
				formattedNodes.dash = nodeItem.dash;
				formattedNodes._members = [];
				if (nodeItem.linkageLevels?.length > 0) {
					for (let i = 0; i < nodeItem.linkageLevels.length; i++) {
						formattedNodes._members.push(nodeItem.linkageLevels[i].id * -1);
					}
				}
				if (nodeItem.nodes) {
					nodeItem.nodes.forEach((node) => {
						if (node.nodeKey) {
							formattedNodes._members.push(node.nodeKey);
						}
					});
				}
			}
			formattedNodes.supers = nodeItem.supers || [];
			gojslinkageLevels.forEach((linkageLevel) => {
				if (linkageLevel.nodes) {
					// Map Subordinate Nodes of Each linkageLevel
					linkageLevel.nodes.forEach((linkageLevelNode) => {
						// If a Node's Nodekey Matches a linkageLevel's SubNode's Nodekey...
						if (linkageLevelNode.nodeKey === nodeItem.nodeKey) {
							// This will enable GoJS to see what nodes each linkageLevel needs to wrap around.
							formattedNodes.supers.push((linkageLevel.id));
						}
					});
				}
			});
			return formattedNodes;
		}
	};
	const gojsNodes = mapData.neighborhood.nodes.map(formatNodes);
	/// /////////////////////////
	// CONNECTIONS FORMATTING //
	/// /////////////////////////
	const formatConnections = (connectionItem) => {
		if (connectionItem !== undefined) {
			const formattedConnections = {};
			formattedConnections.id = connectionItem.id;
			formattedConnections.key = (mapData.neighborhood.connections.indexOf(connectionItem)
             + 1) * -1;
			formattedConnections.from = connectionItem.sourceNodeKey;
			formattedConnections.to = connectionItem.destinationNodeKey;
			return formattedConnections;
		}
	};
	const gojsConnections = mapData.neighborhood.connections.map(formatConnections);
	/// ///////////////////
	// THEME FORMATTING //
	/// ///////////////////
	let nodeShape = 'Rectangle';
	if (mapData.theme.nodeShape === 'Oval') {
		nodeShape = 'Ellipse';
	} else if (mapData.theme.nodeShape === 'Rectangle') {
		nodeShape = 'RoundedRectangle';
	}

	let connectionStyle = ['stroke', 'color'];
	if (mapData.theme.connectionLineStyle === 'Line') {
		connectionStyle = ['stroke', 'color'];
	} else if (mapData.theme.connectionLineStyle === 'Dotted Line') {
		connectionStyle = ['strokeDashArray', 'dash'];
	}

	let nodeBorderStyle = ['stroke', 'color'];
	if (mapData.theme.nodeBorderStyle === 'Line') {
		nodeBorderStyle = ['stroke', 'color'];
	} else if (mapData.theme.nodeBorderStyle === 'Dotted Line') {
		nodeBorderStyle = ['strokeDashArray', 'dash'];
	}

	let formatDirection = 'Top to Bottom';
	if (mapData.theme.layout === 'Top to Bottom') {
		formatDirection = 90;
	} else if (mapData.theme.layout === 'Right to Left') {
		formatDirection = 180;
	} else if (mapData.theme.layout === 'Bottom to Top') {
		formatDirection = 270;
	} else if (mapData.theme.layout === 'Left to Right') {
		formatDirection = 0;
	}

	let curve = null;
	if (mapData.theme.corners = 'rounded') {
		curve = go.Link.Bezier;
	} else if (mapData.theme.corners = 'sharp') {
		curve = null;
	}

	let arrowHeadThickness = 0.8;
	// Note: Need to update styles to proper names
	let arrowHeadStyle = 'Standard';
	if (mapData.theme.arrows = 'Basic') {
		arrowHeadStyle = 'Standard';
		arrowHeadThickness = 0.8;
	} else if (mapData.theme.arrows = 'Thin') {
		arrowHeadStyle = 'Standard';
		arrowHeadThickness = 0.6;
	} else if (mapData.theme.arrows = 'Thick') {
		arrowHeadStyle = 'Standard';
		arrowHeadThickness = 1;
	}
	const formattedTheme = {
		nodeShape,
		nodeFillColor: mapData.theme.nodeFillColor,
		nodeBorderColor: mapData.theme.nodeBorderColor,
		nodeBorderStyle,
		nodeBorderThickness: mapData.theme.nodeBorderStylePx,
		nodeHighlightColor: mapData.theme.nodeHighlightColor,
		connectionLineStyle: connectionStyle,
		connectionLineThickness: mapData.theme.nodeConnectionLinePx,
		connectionLineColor: mapData.theme.nodeConnectionLineColor,
		curve,
		fontName: 'Arial',
		corners: mapData.theme.corners,
		layoutDirection: formatDirection,
		layeringOption: '',
		arrowHeadStyle,
		arrowHeadThickness,
	};

	/// /////////////////////////////
	// FINAL FORMATTED MAP OBJECT //
	/// /////////////////////////////

	const mapDataObj = {
		nodeDataArray: gojsNodes,
		linkDataArray: gojsConnections,
		modelData: formattedTheme,
		skipsDiagramUpdate: false,
	};
	return (mapDataObj);
};

export default formatMapData;
