import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Label } from '@progress/kendo-react-labels';
import FilterMessages from './FilterMessages';
import {
	genders,
	races,
	disabilityCodes,
	languages,
	esolCodes,
	yesNo,
	trueFalse,
	grades,
	assessmentPrograms,
	schoolYears,
} from './StudentLookups';
import './StudentDetails.css';

const mapStateToProps = (state) => {
	const {
		authReducer,
		memberReducer,
	} = state;
	return {
		isAuthenticated: authReducer.isAuthenticated,
		userObj: authReducer.userObj,
		programId: authReducer.assessmentProgram.id,
		roleCode: authReducer.role.code,
		memberData: memberReducer.memberList,
	};
};

const StudentDetails = (props) => {
	const {
		userObj,
		inStudentId,
		inRosterId,
		inRosterName,
		setStudentSelection,
		programId,
		roleCode,
		memberData,
	} = props;
	const formatPlaceholder = {
		year: 'yyyy',
		month: 'mm',
		day: 'dd',
	};

	const [messages, setMessages] = useState([]);
	const [student, setStudent] = useState({
		id: inStudentId ? parseInt(inStudentId, 10) : undefined,
		user: userObj.id,
		roster_id: inRosterId ? parseInt(inRosterId, 10) : null,
		Student_Legal_First_Name: null,
		Student_Legal_Last_Name: null,
		Student_Legal_Middle_Name: null,
		Generation_Code: null,
		Attendance_State_Identifier: null,
		Attendance_Region_Identifier: null,
		Attendance_District_Identifier: null,
		Gender: null,
		Date_of_Birth: null,
		State_Student_Identifier: null,
		Current_School_Year: null,
		Attendance_School_Program_Identifier: null,
		Comprehensive_Race: null,
		Primary_Disability_Code: null,
		Hispanic_Ethnicity: null,
		ESOL_Participation_Code: null,
		Assessment_Program_1: null,
		Active_Start_Date: null,
		Active_End_Date: null,
		Current_Grade_Level: null,
		attributes: {
			School_Entry_Date: null,
			District_Entry_Date: null,
			State_Entry_Date: null,
			Accountability_District_Identifier: null,
			Accountability_School_Identifier: null,
			Local_Student_Identifier: null,
			Gifted_Student: null,
			First_Language: null,
		},
		enrollment_id: null,
	});
	const [rosterId, setRosterId] = useState(null);
	const [studentId, setStudentId] = useState(undefined);

	const [firstName, setFirstName] = useState(null);
	const [lastName, setLastName] = useState(null);
	const [middleName, setMiddleName] = useState(null);
	const [generationCode, setGenerationCode] = useState(null);
	const [attendanceDistrictIdentifier, setAttendanceDistrictIdentifier] = useState(null);
	const [attendanceStateIdentifier, setAttendanceStateIdentifier] = useState(null);
	const [gender, setGender] = useState(null);
	const [dateOfBirth, setDateOfBirth] = useState(null);
	const [stateStudentIdentifier, setStateStudentIdentifier] = useState(null);
	const [currentSchoolYear, setCurrentSchoolYear] = useState(null);
	const [attendanceSchoolProgramIdentifier, setAttendanceSchoolProgramIdentifier] = useState(null);
	const [attendanceRegionIdentifier, setAttendanceRegionIdentifier] = useState(null);
	const [comprehensiveRace, setComprehensiveRace] = useState(null);
	const [primaryDisabilityCode, setPrimaryDisabilityCode] = useState(null);
	const [hispanicEthnicity, setHispanicEthnicity] = useState(null);
	const [esolParticipationCode, setEsolParticipationCode] = useState(null);
	const [assessmentProgram1, setAssessmentProgram1] = useState(null);
	const [activeStartDate, setActiveStartDate] = useState(null);
	const [activeEndDate, setActiveEndDate] = useState(null);
	const [currentGradeLevel, setCurrentGradeLevel] = useState(null);
	const [schoolEntryDate, setSchoolEntryDate] = useState(null);
	const [districtEntryDate, setDistrictEntryDate] = useState(null);
	const [stateEntryDate, setStateEntryDate] = useState(null);
	const [accountabilityDistrictIdentifier, setAccountabilityDistrictIdentifier] = useState(null);
	const [accountabilitySchoolIdentifier, setAccountabilitySchoolIdentifier] = useState(null);
	const [localStudentIdentifier, setLocalStudentIdentifier] = useState(null);
	const [giftedStudent, setGiftedStudent] = useState(null);
	const [firstLanguage, setFirstLanguage] = useState(null);
	const [enrollmentId, setEnrollmentId] = useState(null);

	const searchStudent = () => {
		const searchedStudent = {
			id: inStudentId ? parseInt(inStudentId, 10) : studentId,
			user: userObj.id,
			roster_id: inRosterId ? parseInt(inRosterId, 10) : rosterId,
			Student_Legal_First_Name: firstName || null,
			Student_Legal_Last_Name: lastName || null,
			Student_Legal_Middle_Name: middleName || null,
			Generation_Code: generationCode || null,
			Attendance_State_Identifier: attendanceStateIdentifier
				? attendanceStateIdentifier.code || null : null,
			Attendance_Region_Identifier: attendanceRegionIdentifier
				? attendanceRegionIdentifier.code || null : null,
			Attendance_District_Identifier: attendanceDistrictIdentifier
				? attendanceDistrictIdentifier.code || null : null,
			Gender: gender ? gender.id : null,
			Date_of_Birth: dateOfBirth ? dateOfBirth.toJSON() : null,
			State_Student_Identifier: stateStudentIdentifier || null,
			Current_School_Year: currentSchoolYear ? currentSchoolYear.id : null,
			Attendance_School_Program_Identifier: attendanceSchoolProgramIdentifier
				? attendanceSchoolProgramIdentifier.code || null : null,
			Comprehensive_Race: comprehensiveRace ? comprehensiveRace.id : null,
			Primary_Disability_Code: primaryDisabilityCode ? primaryDisabilityCode.id : null,
			Hispanic_Ethnicity: hispanicEthnicity ? hispanicEthnicity.id : null,
			ESOL_Participation_Code: esolParticipationCode ? esolParticipationCode.id : null,
			Assessment_Program_1: assessmentProgram1 ? assessmentProgram1.id : null,
			Active_Start_Date: activeStartDate ? activeStartDate.toJSON() : null,
			Active_End_Date: activeEndDate ? activeEndDate.toJSON() : null,
			Current_Grade_Level: currentGradeLevel ? currentGradeLevel.id : null,
			attributes: {
				School_Entry_Date: schoolEntryDate ? schoolEntryDate.toJSON() : null,
				District_Entry_Date: districtEntryDate ? districtEntryDate.toJSON() : null,
				State_Entry_Date: stateEntryDate ? stateEntryDate.toJSON() : null,
				Accountability_District_Identifier: accountabilityDistrictIdentifier
					? accountabilityDistrictIdentifier.code || null : null,
				Accountability_School_Identifier: accountabilitySchoolIdentifier
					? accountabilitySchoolIdentifier.code || null : null,
				Local_Student_Identifier: localStudentIdentifier || null,
				Gifted_Student: giftedStudent ? giftedStudent.id : null,
				First_Language: firstLanguage ? firstLanguage.id : null,
			},
		};
		if (searchedStudent.State_Student_Identifier) {
			axios.get(`/api/students/ssid/${searchedStudent.State_Student_Identifier}`, {
				params: {
					programId,
					roleCode,
				},
			})
				.then((res) => {
					if (res.status === 200 && res.data && res.data.id) {
						setStudent(res.data);
					}
				});
		}
		if (searchedStudent.attributes.Local_Student_Identifier) {
			axios.get(`/api/students/localid/${searchedStudent.attributes.Local_Student_Identifier}`, {
				params: {
					programId,
					roleCode,
				},
			})
				.then((res) => {
					if (res.status === 200 && res.data && res.data.id) {
						setStudent(res.data);
					}
				});
		}
	};

	const submitStudent = () => {
		const updatedStudent = {
			id: inStudentId ? parseInt(inStudentId, 10) : studentId,
			user: userObj.id,
			roster_id: inRosterId ? parseInt(inRosterId, 10) : rosterId,
			Student_Legal_First_Name: firstName || null,
			Student_Legal_Last_Name: lastName || null,
			Student_Legal_Middle_Name: middleName || null,
			Generation_Code: generationCode || null,
			Attendance_State_Identifier: attendanceStateIdentifier
				? attendanceStateIdentifier.code || null : null,
			Attendance_Region_Identifier: attendanceRegionIdentifier
				? attendanceRegionIdentifier.code || null : null,
			Attendance_District_Identifier: attendanceDistrictIdentifier
				? attendanceDistrictIdentifier.code || null : null,
			Gender: gender ? gender.id : null,
			Date_of_Birth: dateOfBirth ? dateOfBirth.toJSON() : null,
			State_Student_Identifier: stateStudentIdentifier || null,
			Current_School_Year: currentSchoolYear ? currentSchoolYear.id : null,
			Attendance_School_Program_Identifier: attendanceSchoolProgramIdentifier
				? attendanceSchoolProgramIdentifier.code || null : null,
			Comprehensive_Race: comprehensiveRace ? comprehensiveRace.id : null,
			Primary_Disability_Code: primaryDisabilityCode ? primaryDisabilityCode.id : null,
			Hispanic_Ethnicity: hispanicEthnicity ? hispanicEthnicity.id : null,
			ESOL_Participation_Code: esolParticipationCode ? esolParticipationCode.id : null,
			Assessment_Program_1: assessmentProgram1 ? assessmentProgram1.id : null,
			Active_Start_Date: activeStartDate ? activeStartDate.toJSON() : null,
			Active_End_Date: activeEndDate ? activeEndDate.toJSON() : null,
			Current_Grade_Level: currentGradeLevel ? currentGradeLevel.id : null,
			attributes: {
				School_Entry_Date: schoolEntryDate ? schoolEntryDate.toJSON() : null,
				District_Entry_Date: districtEntryDate ? districtEntryDate.toJSON() : null,
				State_Entry_Date: stateEntryDate ? stateEntryDate.toJSON() : null,
				Accountability_District_Identifier: accountabilityDistrictIdentifier
					? accountabilityDistrictIdentifier.code || null : null,
				Accountability_School_Identifier: accountabilitySchoolIdentifier
					? accountabilitySchoolIdentifier.code || null : null,
				Local_Student_Identifier: localStudentIdentifier || null,
				Gifted_Student: giftedStudent ? giftedStudent.id : null,
				First_Language: firstLanguage ? firstLanguage.id : null,
			},
			enrollment_id: enrollmentId || null,
		};
		if (updatedStudent.id) {
			axios.put(`/api/students/${updatedStudent.id}`, updatedStudent, {
				params: {
					programId,
					roleCode,
				},
			}).then((res) => {
				if (res.status === 201 && res.data) {
					setStudent(updatedStudent);
					setMessages([{ field: 'other', message: 'Successful save' }]);
					setStudentSelection(null);
				} else {
					setMessages([{ field: 'other', message: 'Unknown error' }]);
				}
			}).catch((err) => {
				const { response } = err;
				const { data } = response;
				if (data && data.length && data.length > 0) {
					setMessages(data);
				} else {
					setMessages([{ field: 'other', message: response.statusText || 'Unknown error' }]);
				}
			});
		} else {
			axios.post('/api/students', updatedStudent, {
				params: {
					programId,
					roleCode,
				},
			}).then((res) => {
				if (res.status === 201 && res.data) {
					setStudent(updatedStudent);
					setMessages([{ field: 'other', message: 'Successful save' }]);
					setStudentId(res.data.id);
					setStudentSelection(null);
				} else {
					setMessages([{ field: 'other', message: 'Unknown error' }]);
				}
			}).catch((err) => {
				const { response } = err;
				const { data } = response;
				if (data && data.length && data.length > 0) {
					setMessages(data);
				} else {
					setMessages([{ field: 'other', message: JSON.stringify(response.statusText) || 'Unknown error' }]);
				}
			});
		}
	};

	useEffect(() => {
		const stateObj = student.Attendance_State_Identifier
			? memberData.find((item) => item.code === student.Attendance_State_Identifier)
			: null;
		let regionObj = null;
		let districtObj = null;
		let schoolObj = null;
		if (stateObj && stateObj.levels.length === 3) {
			districtObj = stateObj.children.find(
				(item) => item.code === student.Attendance_District_Identifier,
			);
			schoolObj = districtObj ? districtObj.children.find(
				(item) => item.code === student.Attendance_School_Program_Identifier,
			)
				: null;
		} else if (stateObj) {
			regionObj = stateObj.children.find(
				(item) => item.code === student.Attendance_Region_Identifier,
			);
			districtObj = regionObj ? regionObj.children.find(
				(item) => item.code === student.Attendance_District_Identifier,
			)
				: null;
			schoolObj = districtObj ? districtObj.children.find(
				(item) => item.code === student.Attendance_School_Program_Identifier,
			)
				: null;
		}

		let accDistrictObj = null;
		let accSchoolObj = null;
		if (stateObj && stateObj.levels.length === 3) {
			accDistrictObj = stateObj.children.find(
				(item) => item.code === student.attributes.Accountability_District_Identifier,
			);
			accSchoolObj = accDistrictObj ? accDistrictObj.children.find(
				(item) => item.code === student.attributes.Accountability_School_Identifier,
			)
				: null;
		} else if (stateObj && regionObj) {
			accDistrictObj = regionObj ? regionObj.children.find(
				(item) => item.code === student.attributes.Accountability_District_Identifier,
			)
				: null;
			accSchoolObj = accDistrictObj ? accDistrictObj.children.find(
				(item) => item.code === student.attributes.Accountability_School_Identifier,
			)
				: null;
		}

		setStudentId(student.id);
		setRosterId(student.roster_id);
		setFirstName(student.Student_Legal_First_Name);
		setMiddleName(student.Student_Legal_Middle_Name);
		setLastName(student.Student_Legal_Last_Name);
		setGenerationCode(student.Generation_Code);
		setAttendanceStateIdentifier(stateObj);
		setAttendanceRegionIdentifier(regionObj);
		setAttendanceDistrictIdentifier(districtObj);
		setGender(genders.find((item) => item.id === student.Gender));
		setDateOfBirth(student.Date_of_Birth ? new Date(student.Date_of_Birth) : null);
		setStateStudentIdentifier(student.State_Student_Identifier);
		setCurrentSchoolYear(schoolYears.find((item) => item.id === student.Current_School_Year));
		setAttendanceSchoolProgramIdentifier(schoolObj);
		setComprehensiveRace(races.find((item) => item.id === student.Comprehensive_Race));
		setPrimaryDisabilityCode(
			disabilityCodes.find((item) => item.id === student.Primary_Disability_Code),
		);
		setHispanicEthnicity(yesNo.find((item) => item.id === student.Hispanic_Ethnicity));
		setEsolParticipationCode(
			esolCodes.find((item) => item.id === student.ESOL_Participation_Code),
		);
		setAssessmentProgram1(
			assessmentPrograms.find((item) => item.id === student.Assessment_Program_1),
		);
		setActiveStartDate(student.Active_Start_Date
			? new Date(student.Active_Start_Date) : null);
		setActiveEndDate(student.Active_End_Date
			? new Date(student.Active_End_Date) : null);
		setCurrentGradeLevel(grades.find((item) => item.id === student.Current_Grade_Level));

		setSchoolEntryDate(student.attributes.School_Entry_Date
			? new Date(student.attributes.School_Entry_Date) : null);
		setDistrictEntryDate(student.attributes.District_Entry_Date
			? new Date(student.attributes.District_Entry_Date) : null);
		setStateEntryDate(student.attributes.State_Entry_Date
			? new Date(student.attributes.State_Entry_Date) : null);
		setAccountabilityDistrictIdentifier(accDistrictObj);
		setAccountabilitySchoolIdentifier(accSchoolObj);
		setLocalStudentIdentifier(student.attributes.Local_Student_Identifier);
		setGiftedStudent(trueFalse.find((item) => item.id === student.attributes.Gifted_Student));
		setFirstLanguage(languages.find((item) => item.id === student.attributes.First_Language));
		setEnrollmentId(student.enrollment_id);
	}, [student, memberData]);

	useEffect(() => {
		if (inStudentId && inRosterId) {
			axios.get(`/api/students/${inStudentId}/rosterId/${inRosterId}`, {
				params: {
					programId,
					roleCode,
				},
			})
				.then((res) => {
					if (res.status === 200 && res.data && res.data.id) {
						setStudent(res.data);
					}
				});
		} else if (studentId && rosterId) {
			axios.get(`/api/students/${studentId}/rosterId/${rosterId}`, {
				params: {
					programId,
					roleCode,
				},
			})
				.then((res) => {
					if (res.status === 200 && res.data && res.data.id) {
						setStudent(res.data);
					}
				});
		}
	}, [inStudentId, studentId, setStudent, programId, roleCode, inRosterId, rosterId]);

	const someMessagesFor = (fieldName) => {
		return messages.some((item) => item.field === fieldName);
	};

	/**
	 *
	 * @param {obj} selectedValue => obj that is selected in the dropdown
	 * @param {string} subSet => string property name of obj to return
	 * @returns array, either empty as the prev selector hasnt been populated,
			or the subset array for the next selection
	 */
	const getStateDistricts = (selectedValue, subSet) => {
		if (selectedValue) {
			return selectedValue[subSet];
		}
		return [];
	};

	const getAccountabilityFields = () => {
		if (attendanceStateIdentifier && attendanceStateIdentifier.levels.length === 3) {
			return (
				<>
					<span className="studentField">
						<Label
							id="accountabilitydistrictlabel"
							editorId="accountabilitydistrictcontrol"
							editorValue={accountabilityDistrictIdentifier}
						>
							Accountability District:
						</Label>
						<br />
						<ComboBox
							required={someMessagesFor('Accountability District Identifier')}
							id="accountabilitydistrictcontrol"
							ariaLabelledBy="accountabilitydistrictlabel"
							ariaDescribedBy="accountabilitydistricterror"
							value={accountabilityDistrictIdentifier}
							onChange={(e) => setAccountabilityDistrictIdentifier(e.target.value)}
							data={getStateDistricts(attendanceStateIdentifier, 'children')}
							textField="name"
							style={{ width: '400px' }}
						/>
						<FilterMessages innerId="accountabilitydistricterror" messages={messages} fieldkey="Accountability District Identifier" />
					</span>
					<span className="studentField">
						<Label
							id="accountabilityschoollabel"
							editorId="accountabilityschoolcontrol"
							editorValue={accountabilitySchoolIdentifier}
						>
							Accountability School:
						</Label>
						<br />
						<ComboBox
							required={someMessagesFor('Accountability School Identifier')}
							id="accountabilityschoolcontrol"
							ariaLabelledBy="accountabilityschoollabel"
							ariaDescribedBy="accountabilityschoolerror"
							value={accountabilitySchoolIdentifier}
							onChange={(e) => setAccountabilitySchoolIdentifier(e.target.value)}
							data={getStateDistricts(accountabilityDistrictIdentifier, 'children')}
							textField="name"
							style={{ width: '400px' }}
						/>
						<FilterMessages innerId="accountabilityschoolerror" messages={messages} fieldkey="Accountability School Identifier" />
					</span>
				</>
			);
		}
		return (
			<>
				<span className="studentField">
					<Label
						id="accountabilitydistrictlabel"
						editorId="accountabilitydistrictcontrol"
						editorValue={accountabilityDistrictIdentifier}
					>
						Accountability District:
					</Label>
					<br />
					<ComboBox
						required={someMessagesFor('Accountability District Identifier')}
						id="accountabilitydistrictcontrol"
						ariaLabelledBy="accountabilitydistrictlabel"
						ariaDescribedBy="accountabilitydistricterror"
						value={accountabilityDistrictIdentifier}
						onChange={(e) => setAccountabilityDistrictIdentifier(e.target.value)}
						data={getStateDistricts(attendanceRegionIdentifier, 'children')}
						textField="name"
						style={{ width: '400px' }}
					/>
					<FilterMessages innerId="accountabilitydistricterror" messages={messages} fieldkey="Accountability District Identifier" />
				</span>
				<span className="studentField">
					<Label
						id="accountabilityschoollabel"
						editorId="accountabilityschoolcontrol"
						editorValue={accountabilitySchoolIdentifier}
					>
						Accountability School:
					</Label>
					<br />
					<ComboBox
						required={someMessagesFor('Accountability School Identifier')}
						id="accountabilityschoolcontrol"
						ariaLabelledBy="accountabilityschoollabel"
						ariaDescribedBy="accountabilityschoolerror"
						value={accountabilitySchoolIdentifier}
						onChange={(e) => setAccountabilitySchoolIdentifier(e.target.value)}
						data={getStateDistricts(accountabilityDistrictIdentifier, 'children')}
						textField="name"
						style={{ width: '400px' }}
					/>
					<FilterMessages innerId="accountabilityschoolerror" messages={messages} fieldkey="Accountability School Identifier" />
				</span>
			</>
		);
	};

	const getAttendanceFields = () => {
		if (attendanceStateIdentifier && attendanceStateIdentifier.levels.length === 3) {
			return (
				<>
					<span className="studentField">
						<Label
							className="requiredField"
							id="attendancestatelabel"
							editorId="attendancestatecontrol"
							editorValue={attendanceStateIdentifier}
						>
							State:
						</Label>
						<br />
						<ComboBox
							required={someMessagesFor('Attendance State Identifier')}
							id="attendancestatecontrol"
							ariaLabelledBy="attendancestatecontrol"
							ariaDescribedBy="attendancestateerror"
							data={memberData}
							value={attendanceStateIdentifier}
							textField="name"
							onChange={(e) => setAttendanceStateIdentifier(e.target.value)}
							style={{ width: '200px' }}
						/>
						<FilterMessages innerId="attendancestateerror" messages={messages} fieldkey="Attendance State Identifier" />
					</span>
					<span className="studentField">
						<Label
							className="requiredField"
							id="attendancedistrictlabel"
							editorId="attendancedistrictcontrol"
							editorValue={attendanceDistrictIdentifier}
						>
							District:
						</Label>
						<br />
						<ComboBox
							required={someMessagesFor('Attendance District Identifier')}
							id="attendancedistrictcontrol"
							ariaLabelledBy="attendancedistrictlabel"
							ariaDescribedBy="attendancedistricterror"
							data={getStateDistricts(attendanceStateIdentifier, 'children')}
							value={attendanceDistrictIdentifier}
							textField="name"
							onChange={(e) => setAttendanceDistrictIdentifier(e.target.value)}
							style={{ width: '300px' }}
						/>
						<FilterMessages innerId="attendancedistricterror" messages={messages} fieldkey="Attendance District Identifier" />
					</span>
					<span className="studentField">
						<Label
							className="requiredField"
							id="attendanceschoollabel"
							editorId="attendanceschoolcontrol"
							editorValue={attendanceSchoolProgramIdentifier}
						>
							School:
						</Label>
						<br />
						<ComboBox
							required={someMessagesFor('Attendance School Program Identifier')}
							id="attendanceschoolcontrol"
							ariaLabelledBy="attendanceschoollabel"
							ariaDescribedBy="attendanceschoolerror"
							data={getStateDistricts(attendanceDistrictIdentifier, 'children')}
							value={attendanceSchoolProgramIdentifier}
							textField="name"
							onChange={(e) => setAttendanceSchoolProgramIdentifier(e.target.value)}
							style={{ width: '300px' }}
						/>
						<FilterMessages innerId="attendanceschoolerror" messages={messages} fieldkey="Attendance School Program Identifier" />
					</span>
				</>
			);
		}
		return (
			<>
				<span className="studentField">
					<Label
						className="requiredField"
						id="attendancestatelabel"
						editorId="attendancestatecontrol"
						editorValue={attendanceStateIdentifier}
					>
						State:
					</Label>
					<br />
					<ComboBox
						required={someMessagesFor('Attendance State Identifier')}
						id="attendancestatecontrol"
						ariaLabelledBy="attendancestatecontrol"
						ariaDescribedBy="attendancestateerror"
						data={memberData}
						value={attendanceStateIdentifier}
						textField="name"
						onChange={(e) => setAttendanceStateIdentifier(e.target.value)}
					/>
					<FilterMessages innerId="attendancestateerror" messages={messages} fieldkey="Attendance State Identifier" />
				</span>
				<span className="studentField">
					<Label
						className="requiredField"
						id="attendanceregionlabel"
						editorId="attendanceregioncontrol"
						editorValue={attendanceRegionIdentifier}
					>
						Region:
					</Label>
					<br />
					<ComboBox
						required={someMessagesFor('Attendance Region Identifier')}
						id="attendanceregioncontrol"
						ariaLabelledBy="attendanceregionlabel"
						ariaDescribedBy="attendanceregionerror"
						data={getStateDistricts(attendanceStateIdentifier, 'children')}
						value={attendanceRegionIdentifier}
						textField="name"
						onChange={(e) => setAttendanceRegionIdentifier(e.target.value)}
						style={{ width: '400px' }}
					/>
					<FilterMessages innerId="attendanceregionerror" messages={messages} fieldkey="Attendance Region Identifier" />
				</span>
				<span className="studentField">
					<Label
						className="requiredField"
						id="attendancedistrictlabel"
						editorId="attendancedistrictcontrol"
						editorValue={attendanceDistrictIdentifier}
					>
						District:
					</Label>
					<br />
					<ComboBox
						required={someMessagesFor('Attendance District Identifier')}
						id="attendancedistrictcontrol"
						ariaLabelledBy="attendancedistrictlabel"
						ariaDescribedBy="attendancedistricterror"
						data={getStateDistricts(attendanceRegionIdentifier, 'children')}
						value={attendanceDistrictIdentifier}
						textField="name"
						onChange={(e) => setAttendanceDistrictIdentifier(e.target.value)}
						style={{ width: '400px' }}
					/>
					<FilterMessages innerId="attendancedistricterror" messages={messages} fieldkey="Attendance District Identifier" />
				</span>
				<span className="studentField">
					<Label
						className="requiredField"
						id="attendanceschoollabel"
						editorId="attendanceschoolcontrol"
						editorValue={attendanceSchoolProgramIdentifier}
					>
						School:
					</Label>
					<br />
					<ComboBox
						required={someMessagesFor('Attendance School Program Identifier')}
						id="attendanceschoolcontrol"
						ariaLabelledBy="attendanceschoollabel"
						ariaDescribedBy="attendanceschoolerror"
						data={getStateDistricts(attendanceDistrictIdentifier, 'children')}
						value={attendanceSchoolProgramIdentifier}
						textField="name"
						onChange={(e) => setAttendanceSchoolProgramIdentifier(e.target.value)}
						style={{ width: '400px' }}
					/>
					<FilterMessages innerId="attendanceschoolerror" messages={messages} fieldkey="Attendance School Program Identifier" />
				</span>
			</>
		);
	};

	const getStudentIDFields = () => {
		return (
			<>
				<div className="student-details-section">
					<h2>ID</h2>
					<h4>Please enter either an SSID or Local ID.</h4>
					<span className="studentField">
						<Label
							id="statestudentidentifierlabel"
							editorId="statestudentidentifiercontrol"
							editorValue={stateStudentIdentifier}
						>
							State Student Identifier:
						</Label>
						<br />
						<Input
							required={someMessagesFor('State Student Identifier')}
							id="statestudentidentifiercontrol"
							ariaLabelledBy="statestudentidentifierlabel"
							ariaDescribedBy="statestudentidentifiererror"
							value={stateStudentIdentifier}
							onChange={(e) => setStateStudentIdentifier(e.target.value)}
						/>
						<FilterMessages innerId="statestudentidentifiererror" messages={messages} fieldkey="State Student Identifier" />
					</span>
					<span className="studentField">
						<Label
							id="localstudentidentifierlabel"
							editorId="localstudentidentifiercontrol"
							editorValue={localStudentIdentifier}
						>
							Local Student Identifier:
						</Label>
						<br />
						<Input
							required={someMessagesFor('Local Student Identifier')}
							id="localstudentidentifiercontrol"
							ariaLabelledBy="localstudentidentifierlabel"
							ariaDescribedBy="localstudentidentifiererror"
							value={localStudentIdentifier}
							onChange={(e) => setLocalStudentIdentifier(e.target.value)}
						/>
						<FilterMessages innerId="localstudentidentifiererror" messages={messages} fieldkey="Local Student Identifier" />
					</span>
					<span className="studentField">
						<br />
						<Button
							onClick={searchStudent}
							disabled={(stateStudentIdentifier === null || stateStudentIdentifier === '') && (localStudentIdentifier === null || localStudentIdentifier === '')}
						>
							Search
						</Button>
						<FilterMessages messages={messages} fieldkey="other" />
					</span>
				</div>
			</>
		);
	};

	const getNewStudentFields = () => {
		return (
			<>
				<div className="studentDetailsForm">
					<div className="student-details-section">
						<h2>Student</h2>
						<span className="studentField">
							<Label
								className="requiredField"
								id="firstnamelabel"
								editorId="firstnamecontrol"
								editorValue={firstName}
							>
								Legal First Name:
							</Label>
							<br />
							<Input
								id="firstnamecontrol"
								ariaLabelledBy="firstnamelabel"
								ariaDescribedBy="firstnameerror"
								value={firstName}
								required={someMessagesFor('Student Legal First Name')}
								onChange={(e) => setFirstName(e.target.value)}
							/>
							<FilterMessages innerId="firstnameerror" messages={messages} fieldkey="Student Legal First Name" />
						</span>
						<span className="studentField">
							<Label
								id="middlenamelabel"
								editorId="middlenamecontrol"
								editorValue={middleName}
							>
								Legal Middle Name:
							</Label>
							<br />
							<Input
								required={someMessagesFor('Student Legal Middle Name')}
								id="middlenamecontrol"
								ariaLabelledBy="middlenamelabel"
								ariaDescribedBy="middlenameerror"
								value={middleName}
								onChange={(e) => setMiddleName(e.target.value)}
							/>
							<FilterMessages innerId="middlenameerror" messages={messages} fieldkey="Student Legal Middle Name" />
						</span>
						<span className="studentField">
							<Label
								className="requiredField"
								id="lastnamelabel"
								editorId="lastnamecontrol"
								editorValue={lastName}
							>
								Legal Last Name:
							</Label>
							<br />
							<Input
								id="lastnamecontrol"
								ariaLabelledBy="lastnamelabel"
								ariaDescribedBy="lastnameerror"
								required={someMessagesFor('Student Legal Last Name')}
								value={lastName}
								onChange={(e) => setLastName(e.target.value)}
							/>
							<FilterMessages innerId="lastnameerror" messages={messages} fieldkey="Student Legal Last Name" />
						</span>
						<span className="studentField">
							<Label
								id="generationlabel"
								editorId="generationcontrol"
								editorValue={generationCode}
							>
								Generation:
							</Label>
							<br />
							<Input
								required={someMessagesFor('Generation Code')}
								id="generationcontrol"
								ariaLabelledBy="generationlabel"
								ariaDescribedBy="generationerror"
								value={generationCode}
								onChange={(e) => setGenerationCode(e.target.value)}
							/>
							<FilterMessages innerId="generationerror" messages={messages} fieldkey="Generation Code" />
						</span>
					</div>
					<div className="student-details-section">
						<h2>Demographic</h2>
						<span className="studentField">
							<Label
								id="genderlabel"
								editorId="gendercontrol"
								editorValue={gender}
							>
								Gender:
							</Label>
							<br />
							<ComboBox
								required={someMessagesFor('Gender')}
								id="gendercontrol"
								ariaLabelledBy="genderlabel"
								ariaDescribedBy="gendererror"
								data={genders}
								value={gender}
								textField="label"
								dataItemKey="id"
								onChange={(e) => setGender(e.target.value)}
							/>
							<FilterMessages innerId="gendererror" messages={messages} fieldkey="Gender" />
						</span>
						<span className="studentField">
							<Label
								id="dateofbirthlabel"
								editorId="dateofbirthcontrol"
								editorValue={dateOfBirth}
							>
								Date of Birth:
							</Label>
							<br />
							<DatePicker
								required={someMessagesFor('Date of Birth')}
								id="dateofbirthcontrol"
								ariaLabelledBy="dateofbirthlabel"
								ariaDescribedBy="dateofbirtherror"
								value={dateOfBirth}
								onChange={(e) => setDateOfBirth(e.target.value)}
								formatPlaceholder={formatPlaceholder}
							/>
							<FilterMessages innerId="dateofbirtherror" messages={messages} fieldkey="Date of Birth" />
						</span>
						<span className="studentField">
							<Label
								id="firstlanguagelabel"
								editorId="firstlanguagecontrol"
								editorValue={firstLanguage}
							>
								First Language:
							</Label>
							<br />
							<ComboBox
								required={someMessagesFor('First Language')}
								id="firstlanguagecontrol"
								ariaLabelledBy="firstlanguagelabel"
								ariaDescribedBy="firstlanguageerror"
								data={languages}
								value={firstLanguage}
								textField="label"
								dataItemKey="id"
								onChange={(e) => setFirstLanguage(e.target.value)}
							/>
							<FilterMessages innerId="firstlanguageerror" messages={messages} fieldkey="First Language" />
						</span>
						<span className="studentField">
							<Label
								id="comprehensiveracelabel"
								editorId="comprehensiveracecontrol"
								editorValue={comprehensiveRace}
							>
								Comprehensive Race:
							</Label>
							<br />
							<ComboBox
								required={someMessagesFor('Comprehensive Race')}
								id="comprehensiveracecontrol"
								ariaLabelledBy="comprehensiveracelabel"
								ariaDescribedBy="comprehensiveraceerror"
								data={races}
								value={comprehensiveRace}
								textField="label"
								dataItemKey="id"
								onChange={(e) => setComprehensiveRace(e.target.value)}
							/>
							<FilterMessages innerId="comprehensiveraceerror" messages={messages} fieldkey="Comprehensive Race" />
						</span>
						<span className="studentField">
							<Label
								id="hispanicethnicitylabel"
								editorId="hispanicethnicitycontrol"
								editorValue={hispanicEthnicity}
							>
								Hispanic Ethnicity:
							</Label>
							<br />
							<ComboBox
								required={someMessagesFor('Hispanic Ethnicity')}
								id="hispanicethnicitycontrol"
								ariaLabelledBy="hispanicethnicitylabel"
								ariaDescribedBy="hispanicethnicityerror"
								data={yesNo}
								value={hispanicEthnicity}
								textField="label"
								dataItemKey="id"
								onChange={(e) => setHispanicEthnicity(e.target.value)}
							/>
							<FilterMessages innerId="hispanicethnicityerror" messages={messages} fieldkey="Hispanic Ethnicity" />
						</span>
					</div>
					<div className="student-details-section">
						<h2>Profile</h2>
						<span className="studentField">
							<Label
								id="disabilitycodelabel"
								editorId="disabilitycodecontrol"
								editorValue={primaryDisabilityCode}
							>
								Primary Disability Code:
							</Label>
							<br />
							<ComboBox
								required={someMessagesFor('Primary Disability Code')}
								id="disabilitycodecontrol"
								ariaLabelledBy="disabilitycodelabel"
								ariaDescribedBy="disabilitycodeerror"
								data={disabilityCodes}
								value={primaryDisabilityCode}
								textField="label"
								dataItemKey="id"
								onChange={(e) => setPrimaryDisabilityCode(e.target.value)}
							/>
							<FilterMessages innerId="disabilitycodeerror" messages={messages} fieldkey="Primary Disability Code" />
						</span>
						<span className="studentField">
							<Label
								id="giftedstudentlabel"
								editorId="giftedstudentcontrol"
								editorValue={giftedStudent}
							>
								Gifted Student:
							</Label>
							<br />
							<ComboBox
								required={someMessagesFor('Gifted Student')}
								id="giftedstudentcontrol"
								ariaLabelledBy="giftedstudentlabel"
								ariaDescribedBy="giftedstudenterror"
								data={trueFalse}
								value={giftedStudent}
								textField="label"
								dataItemKey="id"
								onChange={(e) => setGiftedStudent(e.target.value)}
							/>
							<FilterMessages innerId="giftedstudenterror" messages={messages} fieldkey="Gifted Student" />
						</span>
						<span className="studentField">
							<Label
								className="requiredField"
								id="assessmentprogramlabel"
								editorId="assessmentprogramcontrol"
								editorValue={assessmentProgram1}
							>
								Assessment Program:
							</Label>
							<br />
							<ComboBox
								required={someMessagesFor('Assessment Program 1')}
								id="assessmentprogramcontrol"
								ariaLabelledBy="assessmentprogramlabel"
								ariaDescribedBy="assessmentprogramerror"
								data={assessmentPrograms}
								value={assessmentProgram1}
								textField="label"
								dataItemKey="id"
								valid={assessmentProgram1 && assessmentProgram1.id}
								onChange={(e) => setAssessmentProgram1(e.target.value)}
							/>
							<FilterMessages innerId="assessmentprogramerror" messages={messages} fieldkey="Assessment Program 1" />
						</span>
						<span className="studentField">
							<Label
								id="esolcodelabel"
								editorId="esolcodecontrol"
								editorValue={esolParticipationCode}
							>
								ESOL Participation Code:
							</Label>
							<br />
							<ComboBox
								required={someMessagesFor('ESOL Participation Code')}
								id="esolcodecontrol"
								ariaLabelledBy="esolcodelabel"
								ariaDescribedBy="esolcodeerror"
								data={esolCodes}
								value={esolParticipationCode}
								textField="label"
								dataItemKey="id"
								onChange={(e) => setEsolParticipationCode(e.target.value)}
							/>
							<FilterMessages innerId="esolcodeerror" messages={messages} fieldkey="ESOL Participation Code" />
						</span>
					</div>
					<div className="student-details-section">
						<h2>School Enrollment</h2>
						{getAttendanceFields()}
						<span className="studentField">
							<Label
								id="gradelabel"
								className="requiredField"
								editorId="gradecontrol"
								editorValue={currentGradeLevel}
							>
								Grade:
							</Label>
							<br />
							<ComboBox
								required={someMessagesFor('Current Grade Level')}
								id="gradecontrol"
								ariaLabelledBy="gradelabel"
								ariaDescribedBy="gradeerror"
								data={grades}
								value={currentGradeLevel}
								textField="label"
								dataItemKey="id"
								valid={currentGradeLevel && currentGradeLevel.id}
								onChange={(e) => setCurrentGradeLevel(e.target.value)}
								style={{ width: '80px' }}
							/>
							<FilterMessages innerId="gradeerror" messages={messages} fieldkey="Current Grade Level" />
						</span>
						{getAccountabilityFields()}
						<span className="studentField">
							<Label
								id="stateentrydatelabel"
								editorId="stateentrydatecontrol"
								editorValue={stateEntryDate}
							>
								State Entry Date:
							</Label>
							<br />
							<DatePicker
								required={someMessagesFor('State Entry Date')}
								id="stateentrydatecontrol"
								ariaLabelledBy="stateentrydatelabel"
								ariaDescribedBy="stateentrydateerror"
								value={stateEntryDate}
								onChange={(e) => setStateEntryDate(e.target.value)}
								formatPlaceholder={formatPlaceholder}
							/>
							<FilterMessages innerId="stateentrydateerror" messages={messages} fieldkey="State Entry Date" />
						</span>
						<span className="studentField">
							<Label
								id="districtentrydatelabel"
								editorId="districtentrydatecontrol"
								editorValue={districtEntryDate}
							>
								District Entry Date:
							</Label>
							<br />
							<DatePicker
								required={someMessagesFor('District Entry Date')}
								id="districtentrydatecontrol"
								ariaLabelledBy="districtentrydatelabel"
								ariaDescribedBy="districtentrydateerror"
								value={districtEntryDate}
								onChange={(e) => setDistrictEntryDate(e.target.value)}
								formatPlaceholder={formatPlaceholder}
							/>
							<FilterMessages innerId="districtentrydateerror" messages={messages} fieldkey="District Entry Date" />
						</span>
						<span className="studentField">
							<Label
								id="schoolentrydatelabel"
								editorId="schoolentrydatecontrol"
								editorValue={schoolEntryDate}
							>
								School Entry Date:
							</Label>
							<br />
							<DatePicker
								id="schoolentrydatecontrol"
								ariaLabelledBy="schoolentrydatelabel"
								ariaDescribedBy="schoolentrydateerror"
								required={someMessagesFor('School Entry Date')}
								value={schoolEntryDate}
								onChange={(e) => setSchoolEntryDate(e.target.value)}
								formatPlaceholder={formatPlaceholder}
							/>
							<FilterMessages innerId="schoolentrydateerror" messages={messages} fieldkey="School Entry Date" />
						</span>
					</div>
					<div className="student-details-section">
						<h2>Other</h2>
						<span className="studentField">
							<Label
								className="requiredField"
								id="currentschoolyearlabel"
								editorId="currentschoolyearcontrol"
								editorValue={currentSchoolYear}
							>
								School Year:
							</Label>
							<br />
							<ComboBox
								id="currentschoolyearcontrol"
								ariaLabelledBy="currentschoolyearlabel"
								ariaDescribedBy="currentschoolyearerror"
								data={schoolYears}
								value={currentSchoolYear}
								required={someMessagesFor('Current School Year')}
								textField="label"
								dataItemKey="id"
								onChange={(e) => setCurrentSchoolYear(e.target.value)}
							/>
							<FilterMessages innerId="currentschoolyearerror" messages={messages} fieldkey="Current School Year" />
						</span>
						<span className="studentField">
							<Label
								id="activestartdatelabel"
								editorId="activestartdatecontrol"
								editorValue={activeStartDate}
							>
								Active Start Date:
							</Label>
							<br />
							<DatePicker
								required={someMessagesFor('Active Start Date')}
								id="activestartdatecontrol"
								ariaLabelledBy="activestartdatelabel"
								ariaDescribedBy="activestartdateerror"
								value={activeStartDate}
								onChange={(e) => setActiveStartDate(e.target.value)}
								formatPlaceholder={formatPlaceholder}
							/>
							<FilterMessages innerId="activestartdateerror" messages={messages} fieldkey="Active Start Date" />
						</span>
						<span className="studentField">
							<Label
								id="activeenddatelabel"
								editorId="activeenddatecontrol"
								editorValue={activeEndDate}
							>
								Active End Date:
							</Label>
							<br />
							<DatePicker
								required={someMessagesFor('Active End Date')}
								name="Active End Date"
								title="Active End Date"
								id="activeenddatecontrol"
								ariaLabelledBy="activeenddatelabel"
								ariaDescribedBy="activeenddateerror"
								value={activeEndDate}
								onChange={(e) => setActiveEndDate(e.target.value)}
								formatPlaceholder={formatPlaceholder}
							/>
							<FilterMessages innerId="activeenddateerror" messages={messages} fieldkey="Active End Date" />
						</span>
					</div>
					<div>
						<button
							type="button"
							className="btn btn-primary"
							onClick={submitStudent}
						>
							Save
						</button>
						<FilterMessages messages={messages} fieldkey="other" />
					</div>
				</div>
			</>
		);
	};

	return (
		<>
			{'>'}
			<button
				type="button"
				className="btn btn-primary breadcrumb"
				primary={true}
				onClick={() => setStudentSelection(null)}
			>
				{inRosterName}
			</button>
			<div className="studentDetailsForm">
				<div className="student-details-name">
					{inStudentId ? `Student ID: ${inStudentId}` : 'Add Student'}
				</div>
				{getStudentIDFields()}
				{getNewStudentFields()}
			</div>
		</>
	);
};

export default connect(mapStateToProps)(StudentDetails);
