import React from 'react';
import { useSelector } from 'react-redux';
import menuItems from './menuItems.json';
import Tab from './Tab';

const NavBar = () => {
	const { role } = useSelector((state) => ({
		role: state.authReducer.role,
	}));

	return (
		<nav className="navbar navbar-expand-md navbar-dark">
			<div className="container-fluid">
				<div className="collapse navbar-collapse" id="navbarNav">
					<ul className="navbar-nav">
						{menuItems.map((item, $index) => Tab(item, $index, role))}
					</ul>
				</div>

				{/* Hamburger menu icon */}
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon" />
				</button>
			</div>
		</nav>
	);
};

export default NavBar;
