import React from 'react';
import { NavLink } from 'react-router-dom';

const Tab = (item, index, role) => {
	const shouldDisplay = ({ dependsOn }) => {
		if (dependsOn) {
			return dependsOn.includes(role.code);
		}
		return true;
	};

	if (!shouldDisplay(item)) {
		return undefined;
	}

	const {
		pages, path, icon, label,
	} = item;
	if (pages) {
		return (
			<li key={`tab-${index}`} className="nav-item dropdown">
				<a href="#" id="menu" data-bs-toggle="dropdown" className="nav-link dropdown-toggle" data-bs-display="static">{label}</a>
				<ul className="dropdown-menu">
					{pages.filter(shouldDisplay).map((child) => {
						return (
							<li key={index}>
								<a href={child.path} className="dropdown-item">{child.label}</a>
							</li>
						);
					})}
				</ul>
			</li>
		);
	}

	return (
		<li className="nav-item" key={`nav-${index}`}>
			<NavLink key={index} to={path} className="nav-link">
				{' '}
				<i className={icon} />
				{label}
			</NavLink>
		</li>
	);
};

export default Tab;
