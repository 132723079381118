import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import './Teachers.css';

const mapStateToProps = (state) => {
	const { authReducer } = state;
	return {
		isAuthenticated: authReducer.isAuthenticated,
		programId: authReducer.assessmentProgram.id,
		roleCode: authReducer.role.code,
		userObj: authReducer.userObj,
	};
};

const Teachers = (props) => {
	const {
		userObj,
		programId,
		roleCode,
		isAuthenticated,
	} = props;
	const [gridData, setGridData] = useState([]);
	const history = useHistory();

	const navigateToRosters = (teacher) => {
		history.push('/rosters', [teacher]);
	};

	// If we're not authenticated, start on the login page.
	if (!isAuthenticated) {
		history.push('/login');
	}

	useEffect(() => {
		axios.get(`/api/teachers/${userObj.id}`, {
			params: {
				programId,
				roleCode,
			},
		})
			.then((res) => {
				if (res.status === 200 && res.data.length) {
					setGridData(res.data);
				} else {
					setGridData([]);
				}
			})
			.catch(() => {
				setGridData([]);
			});
	}, [setGridData, userObj, programId, roleCode]);

	return (
		<div className="rosters-container">
			<Grid
				data={gridData}
				onRowClick={(e) => {
					navigateToRosters(e.dataItem);
				}}
			>
				<Column field="first_name" title="First Name" />
				<Column field="last_name" title="Last Name" />
				<Column field="roster_count" title="# of Classes" />
				<Column field="other" title="Other Information" />
			</Grid>
		</div>
	);
};

export default connect(mapStateToProps)(Teachers);
