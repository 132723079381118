/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-bind */
import React, { useRef, useEffect } from 'react';

import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';
import PropTypes from 'prop-types';

import { SuperNodeLayout } from './utils/superNodeLayout';

import './canvasPage.css';

const Canvas = ({
	nodeDataArray, linkDataArray, modelData, skipsDiagramUpdate,
}) => {
	const diagramRef = useRef(null);

	useEffect(() => {
		if (diagramRef.current === null);
	}, [modelData]);
	/// ///////////////////
	// MAP BUILD STARTS //
	/// ///////////////////
	function initDiagram() {
		/// /////////////////
		// DIAGRAM STARTS //
		/// /////////////////
		const $ = go.GraphObject.make;

		go.Diagram.licenseKey = process.env.REACT_APP_GOJS_KEY || '';

		const diagram = $(go.Diagram, {
			allowCopy: false,
			allowDelete: false,
			'draggingTool.isEnabled': false,
			'panningTool.isEnabled': false,
			'undoManager.isEnabled': true, // must be set to allow for model change listening
			layout: $(SuperNodeLayout, {
				isOngoing: false,
				layerSpacing: 50,
				columnSpacing: 50,
			}),
			model: $(go.GraphLinksModel, {
				nodeKeyProperty: 'nodeKey',
				linkKeyProperty: 'id',
			}),
		});
		/// ////////////////
		// CLICK HANDLERS //
		/// ////////////////
		function click(e, obj) {
			if (e.shift === false) {
				diagram.clearHighlighteds();
			}

			diagram.findNodeForKey(obj.data.nodeKey).isHighlighted = true;
			if (obj.data._members) {
				obj.data._members.forEach((member) => {
					diagram.findNodeForKey(member).isHighlighted = true;
				});
			}
		}
		/// ///////////////////////
		// NODE TEMPLATE DESIGN //
		/// ///////////////////////
		diagram.nodeTemplate = $(go.Node, 'Auto', // the Shape will go around the TextBlock
			{
				click,
			},
			new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),
			$(go.Shape,
				{
					parameter1: 8, // corner radius
					portId: '',
					fromSpot: go.Spot.AllSides,
					toSpot: go.Spot.AllSides,
					strokeWidth: 2,
				},
				new go.Binding('figure', 'nodeShape').ofModel(),
				new go.Binding('fill', 'nodeFillColor').ofModel(),
				new go.Binding('stroke', 'nodeBorderColor').ofModel(),
				// new go.Binding("strokeWidth", "nodeBorderThickness").ofModel(),
				new go.Binding('stroke', 'color'),
				new go.Binding('strokeDashArray', 'dash'),
				new go.Binding('stroke', 'isHighlighted', ((h) => { return h ? 'red' : 'rgb(21,96,183)'; })).ofObject()),
			$(go.TextBlock,
				{
					margin: 12,
					editable: false,
					font: '100 16px Arial',
					name: 'TEXT',
				},
				new go.Binding('text', 'title').makeTwoWay(),
				new go.Binding('stroke', 'isHighlighted', ((h) => { return h ? 'red' : 'rgb(21,96,183)'; })).ofObject()));
		/// ///////////////////////////////
		// NEIGHBORHOOD TEMPLATE DESIGN //
		/// ///////////////////////////////
		diagram.nodeTemplateMap.add('Super',
			$(go.Node, 'Auto',
				{
					locationObjectName: 'BODY',
					layerName: 'Background',
					movable: false,
					click,
				},
				$(go.Shape, 'RoundedRectangle',
					{
						// fill: 'rgba(21,96,183, 0.1)',
						parameter1: 20,
						spot1: go.Spot.TopLeft,
						spot2: go.Spot.BottomRight,
						minSize: new go.Size(50, 50),
						strokeWidth: 1,
					},
					new go.Binding('fill', 'color'),
					new go.Binding('stroke', 'isHighlighted', ((h) => { return h ? 'red' : 'black'; })).ofObject()),
				$(go.Panel, 'Vertical',
					{ margin: 20 },
					$(go.TextBlock,
						{
							font: '700 20px sans-serif', margin: new go.Margin(5, 5, 10, 5), stroke: 'rgb(21,96,183)', name: 'TEXT',
						},
						new go.Binding('text', 'title'),
						new go.Binding('stroke', 'isHighlighted', ((h) => { return h ? 'red' : 'rgb(21,96,183)'; })).ofObject()),
					$(go.Shape,
						{ name: 'BODY', opacity: 0 }))));
		/// /////////////////////////////
		// CONNECTION TEMPLATE DESIGN //
		/// /////////////////////////////
		diagram.linkTemplate = $(go.Link, // the whole link panel
			{
				routing: go.Link.Orthogonal,
				corner: 10,
			},
			$(go.Shape, // the link shape
				{ strokeWidth: 1.5 }),
			new go.Binding('stroke', 'color'),
			new go.Binding('strokeDashArray', 'dash'),

			$(go.Shape, // the arrowhead
				{ stroke: null },
				new go.Binding('toArrow', 'arrowHeadStyle').ofModel()));

		diagram.commandHandler.selectAll();

		diagram.addDiagramListener('BackgroundSingleClicked', () => {
			diagram.clearHighlighteds();
		});

		return diagram;
	}
	/// ///////////////////////
	// RETURN DIAGRAM BUILD //
	/// ///////////////////////
	return (
		<div className="gojs-wrapper-div">
			<ReactDiagram
				divClassName="diagram-component"
				ref={diagramRef}
				nodeDataArray={nodeDataArray}
				linkDataArray={linkDataArray}
				skipsDiagramUpdate={skipsDiagramUpdate}
				modelData={modelData}
				// onModelChange={onModelChange}
				initDiagram={initDiagram}
			/>
		</div>
	);
};

Canvas.propTypes = {
	nodeDataArray: PropTypes.array,
	linkDataArray: PropTypes.array,
	skipsDiagramUpdate: PropTypes.bool,
	modelData: PropTypes.object,
};

export default Canvas;
