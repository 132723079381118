/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { toast } from 'react-toastify';
import MapAssignment from './MapAssignment';
import WritingSample from '../rosters/WritingSample';
import StudentMaps from './StudentMaps';
import StudentNotes from './StudentNotes';
import './StudentProfile.css';

const mapStateToProps = (state) => {
	const { authReducer } = state;
	return {
		programId: authReducer.assessmentProgram.id,
		roleCode: authReducer.role.code,
	};
};

const StudentProfile = (props) => {
	const {
		rosterId, rosterName, studentId, studentName, setStudentSelection,
		programId, roleCode,
	} = props;
	const [cycleIds, setCycleIds] = useState([]);
	const [activeCycle, setActiveCycle] = useState(null);
	const [currentCycleData, setCurrentCycleData] = useState(null);
	const [surveyData, setSurveyData] = useState(null);
	const [surveyId, setSurveyId] = useState(null);
	const [surveyBaseUrl, setSurveyBaseUrl] = useState(null);
	const [surveyClientName, setSurveyClientName] = useState(null);
	const [mapIEW, setMapIEW] = useState(null);
	const [mapOW, setMapOW] = useState(null);
	const [wlrFIGMap, setWlrFIGMap] = useState(null);
	const [studentStandards, setStudentStandards] = useState();
	const [studentWritingTools, setStudentWritingTools] = useState();
	const [studentWritingRoutine, setStudentWritingRoutine] = useState();
	const [visible, setVisible] = useState();
	const [studentDialogVisible, setStudentDialogVisible] = useState();
	const [dataItem, setDataItem] = useState(false);
	const [selectedWritingLevel, setWritingLevel] = useState();

	const updateSurveyDataDependencies = useEffect(() => {
		if (
			surveyData
			&& surveyData?.observationResponses
			&& surveyData.observationResponses.length > 0
		) {
			Array.from(surveyData.observationResponses).forEach((element) => {
				if (element.response !== '') {
					const surveyOptionSelected = element.response.trim();
					axios.post('/api/surveyQuestionnaireDetails',
						{
							assignmentName: 'Informative and Explanatory Writing',
							optionSelected: surveyOptionSelected,
						}, {
							params: {
								programId,
								roleCode,
							},
						}).then((response) => {
						if (response.data?.length > 0) {
							if (response.data[0].ui_display !== '') {
								setMapIEW(response.data[0].ui_display);
							}
						}
					});
					axios.post('/api/surveyQuestionnaireDetails',
						{
							assignmentName: 'Opinion Writing',
							optionSelected: surveyOptionSelected,
						}, {
							params: {
								programId,
								roleCode,
							},
						}).then((response) => {
						if (response.data?.length > 0) {
							if (response.data[0].ui_display !== '') {
								setMapOW(response.data[0].ui_display);
							}
						}
					});
					axios.post('/api/surveyQuestionnaireDetails',
						{
							assignmentName: 'Information Gathering',
							optionSelected: surveyOptionSelected,
						}, {
							params: {
								programId,
								roleCode,
							},
						}).then((response) => {
						if (response.data?.length > 0) {
							if (response.data[0].ui_display !== '') {
								setWlrFIGMap(response.data[0].ui_display);
							}
						}
					});
				}
			});
		}
	}, [programId, roleCode, surveyData]);

	const changeCycle = useCallback((newCycleId) => {
		setActiveCycle(newCycleId);

		if (newCycleId) {
			// To prevent stale data, retrieve cycle data with each change
			axios.get(`/api/cycle/${newCycleId}`, {
				params: {
					programId,
					roleCode,
				},
			})
				.then((res) => {
					setCurrentCycleData(res.data.length ? res.data[0] : null);
				});
			// fetch survey response for cycle id
			axios.get(`/api/surveyResponseDetails/studentActiveCycleId/${newCycleId}`, {
				params: {
					programId,
					roleCode,
				},
			})
				.then((res) => {
					setSurveyData(res.data.survey_response);
				});
		} else {
			// Cycle invalid. Wipe related data.
			setCurrentCycleData(null);
			setSurveyData(null);
		}
	}, [programId, roleCode]);

	const createNewCycle = useCallback(() => {
		axios.post(`/api/cycle/student/${studentId}/roster/${rosterId}`, {}, {
			params: {
				programId,
				roleCode,
			},
		})
			.then((result) => {
				if (result.status === 200 && result.data.length) {
					setCycleIds(cycleIds.concat(result.data));
					changeCycle(result.data[0].id);
				}
			});
	}, [changeCycle, cycleIds, programId, roleCode, rosterId, studentId]);

	const toggleDialog = () => {
		setVisible(!visible);
	};

	const markComplete = () => {
		axios.put(`/api/cycle/complete/${activeCycle}`, {}, {
			params: {
				programId,
				roleCode,
			},
		})
			.then(() => {
				// Ensure that new data is retrieved from db
				changeCycle(activeCycle);
				toggleDialog();
			});
	};

	const toggleStudentDelDialog = () => {
		setStudentDialogVisible(!studentDialogVisible);
	};

	const surveyResponseDetails = useCallback(() => {
		axios.post(`/api/surveyResponseDetails/studentActiveCycleId/${activeCycle}/surveyId/${surveyId}`, {}, {
			params: {
				programId,
				roleCode,
			},
		})
			.then((res) => {
				if (res.status === 200) {
					setSurveyData(res.data.survey_response);
					changeCycle(activeCycle);
				}
			});
	}, [activeCycle, changeCycle, programId, roleCode, surveyId]);

	const takeSurveyClick = useCallback(() => {
		axios.get(`/api/surveyDetails/fetchCompletedSurvey/studentActiveCycleId/${activeCycle}/surveyId/${surveyId}`, {
			params: {
				programId,
				roleCode,
			},
		}).then((res) => {
			if (res.status === 200) {
				setDataItem(true);
			}
		})
			.catch((err) => {
				if (err) {
					toast.error('An unexpected error occurred while loading the survey');
				}
			});
	}, [activeCycle, programId, roleCode, surveyId]);

	// Get student data from database
	useEffect(() => {
		// get student profile survey response
		axios.get(`/api/students/${studentId}/rosterId/${rosterId}`, {
			params: {
				programId,
				roleCode,
			},
		})
			.then((res) => {
				if (res.status === 200) {
					if (res.data) {
						let writingToolsResponse = '';
						Array.from(res.data.soy_survey_response.observationResponses).forEach((element) => {
							if (element.response !== '') {
								const studentStandardQuestion = 'Which set of academic standards are used for this students instruction?';
								if (element.questionName.replace(/[“”‘’'"]/g, '') === studentStandardQuestion) {
									setStudentStandards(element.response.trim());
								}
								const writingRoutineQuestion = 'Based on the following writing routine descriptions, indicate the students highest level.';
								if (element.questionName.replace(/[“”‘’'"]/g, '') === writingRoutineQuestion) {
									setStudentWritingRoutine(element.response.trim());
								}
								// writing tools
								if (element.questionName === 'Pencil Size or Grips' || element.questionName === 'Crayons/Markers'
									|| element.questionName === 'Desktop Helpers (e.g., Alphabet Strips, Cursive Letters)' || element.questionName === 'Tactile (e.g., Puffy Paint)'
									|| element.questionName === 'Visual (e.g., enlarge for CVI)' || element.questionName === 'Stabilized writing surfaces (e.g., slant board)'
									|| element.questionName === 'Adapted papers' || element.questionName === 'Writing templates') {
									writingToolsResponse = `${writingToolsResponse + element.questionName} - ${element.response.trim()}; `;
									setStudentWritingTools(writingToolsResponse);
								}
							}
						});
					}
				}
			});
		// Get survey URL details
		axios.get('/api/surveyDetails/configType/survey_details', {
			params: {
				programId,
				roleCode,
			},
		})
			.then((res) => {
				if (res.status === 200 && res.data.length) {
					setSurveyId(res.data[0].configuration.survey_id);
					setSurveyBaseUrl(res.data[0].configuration.survey_base_url);
					setSurveyClientName(res.data[0].configuration.client_name);
				}
			});

		// Get all cycles for student/roster combo
		axios.get(`/api/cycle/student/${studentId}/roster/${rosterId}`, {
			params: {
				programId,
				roleCode,
			},
		})
			.then((res) => {
				if (res.status === 200 && res.data.length) {
					// Populate cycle list and select the most recent
					setCycleIds(res.data);
					changeCycle(res.data[res.data.length - 1].id);
				} else if (res.status === 200) {
					// No existing cycles. Create a new one.
					setCycleIds([]);
					axios.post(`/api/cycle/student/${studentId}/roster/${rosterId}`, {}, {
						params: {
							programId,
							roleCode,
						},
					})
						.then((result) => {
							if (result.status === 200 && result.data.length) {
								setCycleIds(result.data);
								changeCycle(result.data[0].id);
							}
						});
				} else {
					// Something went wrong. Zero everything out.
					setCycleIds([]);
					changeCycle(null);
				}
			})
			.catch(() => {
				// Something went wrong. Zero everything out.
				setCycleIds([]);
				changeCycle(null);
			});
	}, [setCycleIds, rosterId, studentId, changeCycle, programId, roleCode]);

	// Build student cycle details table
	let detailsTable = 'Retrieving data. Please wait.';
	let allMapsAssigned = true;
	let writingMapAssign = true;
	let gatheringMapAssign = true;
	const pBarItems = [];
	const cycleNav = [];

	if (activeCycle) {
		const cycleIndex = cycleIds.findIndex((el) => el.id === activeCycle);
		if (cycleIndex) {
			cycleNav.push(
				<button
					type="button"
					className="btn btn-primary"
					id="cycle-back"
					key="cycleBack"
					primary={true}
					onClick={() => changeCycle(cycleIds[cycleIndex - 1].id)}
				>
					View Previous Cycle
				</button>,
			);
		}
		if (cycleIndex < (cycleIds.length - 1)) {
			cycleNav.push(
				<button
					type="button"
					className="btn btn-primary"
					id="cycle-forward"
					key="cycleForward"
					primary={true}
					onClick={() => changeCycle(cycleIds[cycleIndex + 1].id)}
				>
					View Next Cycle
				</button>,
			);
		}

		let cycleStatus = 'NA';
		if (!currentCycleData) {
			// Should only happen if we're waiting on calls or there's been a horrible error.
			return 'Loading. Please wait';
		}

		const mapAssignmentDetails = [];
		let newCycleButton = null;
		// TODO: never see this even after assigning both maps cluster and levels?
		if (currentCycleData?.completion_date) {
			// Cycle completed. This cycle is historical
			const completeString = new Date(currentCycleData.completion_date).toLocaleDateString();
			cycleStatus = `Completed ${completeString}`;

			// Only add button to create new cycle if this is the last historical cycle
			if ((cycleIds.findIndex((el) => el.id === activeCycle)) >= (cycleIds.length - 1)) {
				newCycleButton = (
					<button
						type="button"
						className="btn btn-primary"
						id="cycle-new"
						primary={true}
						onClick={() => createNewCycle()}
					>
						Create New Cycle
					</button>
				);
			}

			currentCycleData.map_assignments.forEach((ma) => {
				// Something went wrong. Don't display "null" maps
				if (!ma.id) {
					return;
				}

				let sgVal = 'NA';
				if (ma.assignment_name === 'Informative and Explanatory Writing' || ma.assignment_name === 'Writing Production') {
					sgVal = mapIEW;
				} else if (ma.assignment_name === 'Opinion Writing') {
					sgVal = mapOW;
				} else if (ma.assignment_name === 'Information Gathering' || ma.assignment_name === 'Gathering Information') {
					sgVal = wlrFIGMap;
				}

				let cluster = 'NA';
				if (ma.cluster === 'C') {
					cluster = 'Conventional';
				} else if (ma.cluster === 'T') {
					cluster = 'Transitional';
				} else if (ma.cluster === 'E') {
					cluster = 'Emergent';
				} else {
					cluster = sgVal;
				}

				let gradeBand = 'NA';
				if (ma.grade_band === 'K2') {
					gradeBand = 'Kindergarten - Grade 2';
				} else if (ma.grade_band === '24') {
					gradeBand = 'Grade 2 - Grade 4';
				} else if (ma.grade_band === '46') {
					gradeBand = 'Grade 4 - Grade 6';
				}

				const clusterLevel = ma.cluster_level ? ma.cluster_level : 'NA';
				// Build profile rows
				mapAssignmentDetails.push(
					<tr key={`${ma.map_ee}_map_display`}>
						<td>{`${ma.assignment_name} Node Cluster`}</td>
						<td>{`${gradeBand} : ${cluster || sgVal} : ${clusterLevel}`}</td>
					</tr>,
				);

				// Build map displays
				pBarItems.push(
					<PanelBarItem
						title={`${ma.assignment_name} ${cluster}`}
						key={`${ma.map_ee}_map_display`}
					>
						<StudentMaps
							cycleId={activeCycle}
							mapAssignment={ma}
							reload={changeCycle}
							suggestedVal={sgVal}
							historical={true}
							passedWritingLevel={selectedWritingLevel}
						/>
					</PanelBarItem>,
				);
			});
		} else if (!surveyData) {
			cycleStatus = 'Awaiting Student Writing Survey';
		} else {
			// Time to find out if maps are assigned:
			cycleStatus = 'Awaiting Map Assignment';

			currentCycleData.map_assignments.forEach((ma) => {
				// Something went wrong. Don't display "null" maps
				if (!ma.id) {
					return;
				}

				let gradeBand = 'NA';
				if (ma.grade_band === 'K2') {
					gradeBand = 'Kindergarten - Grade 2';
				} else if (ma.grade_band === '24') {
					gradeBand = 'Grade 2 - Grade 4';
				} else if (ma.grade_band === '46') {
					gradeBand = 'Grade 4 - Grade 6';
				}

				const clusterLevel = ma.cluster_level ? ma.cluster_level : 'NA';

				let sgVal = 'NA';
				if (ma.assignment_name === 'Informative and Explanatory Writing' || ma.assignment_name === 'Writing Production') {
					sgVal = mapIEW;
				} else if (ma.assignment_name === 'Opinion Writing') {
					sgVal = mapOW;
				} else if (ma.assignment_name === 'Information Gathering' || ma.assignment_name === 'Gathering Information') {
					sgVal = wlrFIGMap;
				}

				let cluster = 'NA';
				if (ma.cluster === 'C') {
					cluster = 'Conventional';
				} else if (ma.cluster === 'T') {
					cluster = 'Transitional';
				} else if (ma.cluster === 'E') {
					cluster = 'Emergent';
				} else {
					cluster = sgVal;
				}
				// Build profile rows
				mapAssignmentDetails.push(
					<tr key={`${ma.map_ee}_map_display`}>
						<td>{`${ma.assignment_name} Node Cluster`}</td>
						<td>{`${gradeBand} : ${cluster || sgVal} : ${clusterLevel}`}</td>
					</tr>,
				);
				// Build map displays
				pBarItems.push(
					<PanelBarItem
						title={`${ma.assignment_name} ${cluster}`}
						key={`${ma.map_ee}_map_display`}
					>
						<StudentMaps
							cycleId={activeCycle}
							mapAssignment={ma}
							reload={changeCycle}
							suggestedVal={sgVal}
							historical={false}
							passedWritingLevel={selectedWritingLevel}
						/>
					</PanelBarItem>,
				);

				if (!ma.cluster_level) {
					allMapsAssigned = false;
				}
				if ((ma.assignment_name === 'Informative and Explanatory Writing' && !ma.cluster_level) || (ma.assignment_name === 'Writing Production' && !ma.cluster_level)) {
					writingMapAssign = false;
					const itemIdx = pBarItems.findIndex((item) => item.key.includes(ma.map_ee));
					pBarItems[itemIdx] = (
						<PanelBarItem
							title={`${ma.assignment_name}: Action Needed`}
							key={`${ma.map_ee}_map_assign`}
						>
							<MapAssignment
								cycleId={activeCycle}
								mapAssignment={ma}
								reload={changeCycle}
								suggestedVal={sgVal}
								onSetWritingLevel={setWritingLevel}
							/>
						</PanelBarItem>
					);
				}

				if (ma.assignment_name === 'Opinion Writing' && !ma.cluster_level) {
					gatheringMapAssign = false;
					const itemIdx = pBarItems.findIndex((item) => item.key.includes(ma.map_ee));
					pBarItems[itemIdx] = (
						<PanelBarItem
							title={`${ma.assignment_name}: Action Needed`}
							key={`${ma.map_ee}_map_assign`}
						>
							<MapAssignment
								cycleId={activeCycle}
								mapAssignment={ma}
								reload={changeCycle}
								suggestedVal={sgVal}
								onSetWritingLevel={setWritingLevel}
							/>
						</PanelBarItem>
					);
				}

				if ((ma.assignment_name === 'Information Gathering' && !ma.cluster_level) || (ma.assignment_name === 'Gathering Information' && !ma.cluster_level)) {
					gatheringMapAssign = false;
					const itemIdx = pBarItems.findIndex((item) => item.key.includes(ma.map_ee));
					pBarItems[itemIdx] = (
						<PanelBarItem
							title={`${ma.assignment_name}: Action Needed`}
							key={`${ma.map_ee}_map_assign`}
						>
							<MapAssignment
								cycleId={activeCycle}
								mapAssignment={ma}
								reload={changeCycle}
								suggestedVal={sgVal}
								onSetWritingLevel={setWritingLevel}
							/>
						</PanelBarItem>
					);
				}
			});

			if (allMapsAssigned) {
				// Map assigned, survey completed, but cycle not completed
				cycleStatus = (
					<div>
						<button type="button" className="btn btn-primary" onClick={toggleDialog}>
							Mark Cycle Complete
						</button>
						{visible && (
							<Dialog title="Please confirm" onClose={toggleDialog}>
								<p style={{ margin: '25px', textAlign: 'center' }}>
									Are you sure you want to complete this cycle?
								</p>
								<DialogActionsBar>
									<button type="button" className="btn btn-primary" onClick={toggleDialog}>
										No
									</button>
									<button type="button" className="btn btn-primary" onClick={markComplete}>
										Yes
									</button>
								</DialogActionsBar>
							</Dialog>
						)}
					</div>
				);
			}
		}

		detailsTable = (
			<table className="student-profile-details-table" role="presentation">
				<tbody>
					{mapAssignmentDetails}
					<tr>
						<td>Student Standards</td>
						<td>{studentStandards}</td>
					</tr>
					<tr>
						<td>Writing Tools</td>
						<td>{studentWritingTools}</td>
					</tr>
					<tr>
						<td>Writing Routine</td>
						<td>{studentWritingRoutine}</td>
					</tr>
					<tr>
						<td>Status</td>
						<td>
							{cycleStatus}
							{newCycleButton}
						</td>
					</tr>
				</tbody>
			</table>
		);
	}

	// Build PanelBar items
	let panelBarItems = [];
	const url = `${surveyBaseUrl}/#uuid=${surveyId}&client=${surveyClientName}&externalId=${activeCycle}`;
	if (surveyData) {
		panelBarItems.push(
			<PanelBarItem
				title="Survey"
				key="survey"
			>
				<Grid
					data={surveyData.observationResponses}
				>
					<Column field="questionName" title="Questions" />
					<Column field="response" title="Responses" />
				</Grid>
			</PanelBarItem>,
		);

		if (allMapsAssigned) {
			panelBarItems = panelBarItems.concat(pBarItems);
			panelBarItems.push(
				<PanelBarItem
					title="Notes"
					key="notes"
				>
					<StudentNotes cycleId={activeCycle} />
				</PanelBarItem>,
			);
			panelBarItems.push(
				<PanelBarItem
					title="Writing Samples"
					key="samples"
				>
					<WritingSample cycleId={activeCycle} />
				</PanelBarItem>,
			);
		} else {
			panelBarItems = panelBarItems.concat(pBarItems);
		}
	} else if (activeCycle) {
		panelBarItems.push(
			<PanelBarItem
				title="Survey: Action Needed. Get Survey Responses"
				key="survey"
			>
				<div>
					<a
						href={url}
						target="blank"
						rel="noreferrer"
						onClick={() => takeSurveyClick()}
					>
						Click here to take the survey
					</a>
					{
						dataItem || currentCycleData?.survey_status === 'IN PROGRESS' || currentCycleData?.survey_status === 'COMPLETE' ? (
							<button
								type="button"
								title="Continue"
								className="btn btn-primary survey_button"
								primary={true}
								onClick={() => surveyResponseDetails()}
							>
								Get Survey Response
							</button>
						) : null
					}
				</div>
			</PanelBarItem>,
		);
	}

	const onDeleteStudent = () => {
		axios.put(`/api/students/${studentId}/delete`, {}, {
			params: {
				programId,
				roleCode,
			},
		})
			.then(() => {
				setStudentSelection(null);
			})
			.catch(() => {
				setStudentSelection(null);
			});
	};

	return (
		<>
			{'>'}
			<button
				type="button"
				className="btn btn-primary breadcrumb"
				primary={true}
				onClick={() => setStudentSelection(null)}
			>
				{rosterName}
			</button>
			<div className="student-profile-container">
				<div className="student-profile-header">
					{`Profile for ${studentName}:`}
				</div>
				<div>
					<p>
						Next, you will complete a Writing Survey for this student.
						This information helps Navigator determine which map assignment
						is most appropriate for your student.
					</p>
					<p>
						To complete the survey,
						select the Survey banner below and follow the link to take the survey.
						Once the survey is completed,
						return to this screen and select the Get Survey Response button.
					</p>
					<p>
						The Student Profile table contains a summary about your student.
						Until the Writing Survey has been completed and map assignments
						have been made, the first three rows of the table will read NA.
					</p>
					<p>
						These will update automatically once your student has been assigned to all three maps.
					</p>
				</div>
				<div>
					<button type="button" className="btn btn-primary bi bi-trash" onClick={toggleStudentDelDialog} primary style={{ backgroundColor: 'red', marginBottom: '10px', marginTop: '10px' }}>
						&nbsp;
						Delete Student
					</button>
					{studentDialogVisible && (
						<Dialog title="Please confirm" onClose={toggleStudentDelDialog}>
							<p style={{ margin: '25px', textAlign: 'center' }}>
								Are you sure you want to delete this student?
							</p>
							<DialogActionsBar>
								<button
									type="button"
									className="btn btn-primary"
									onClick={toggleStudentDelDialog}
								>
									No
								</button>
								<button
									type="button"
									className="btn btn-primary"
									onClick={onDeleteStudent}
								>
									Yes
								</button>
							</DialogActionsBar>
						</Dialog>
					)}
				</div>
				{detailsTable}
				<div className="cycle-nav-container">
					{cycleNav}
				</div>
				<PanelBar>
					{panelBarItems}
				</PanelBar>
			</div>
		</>
	);
};

export default connect(mapStateToProps)(StudentProfile);
