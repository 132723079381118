import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './News.css';
import { connect } from 'react-redux';

// Internal presentational component for News items
const Announcement = (props) => {
	const { title, body } = props;

	return (
		<div>
			<h2>{title}</h2>
			<p>{body}</p>
		</div>
	);
};

// Default blank announcement to show for programs that don't have any active
const blankAnnouncements = [
	// <Announcement
	// 	key="blankAnnouncement"
	// 	title="Announcements"
	// 	body="Nothing to report! Check back later."
	// />,
];

const mapStateToProps = (state) => {
	const { authReducer } = state;
	return {
		programId: authReducer.assessmentProgram.id,
		roleCode: authReducer.role.code,
	};
};

/**
 * The news component holds any information we want visitors to see on the landing page.
 *
 * @component
 * @example
 * <News />
 */
const News = (props) => {
	const { numToDisplay, programId, roleCode } = props;
	const [announcements, setAnnouncements] = useState(blankAnnouncements);

	// Get announcements from server
	useEffect(() => {
		if (programId) {
			axios
				.get('/api/announcements', {
					params: {
						programId,
						roleCode,
						num: numToDisplay,
					},
				})
				.then((res) => {
					if (res.status === 200 && res.data.length) {
						const resAnnouncements = res.data.map((ann) => {
							return (
								<Announcement
									key={ann.title}
									title={ann.title}
									body={ann.body}
								/>
							);
						});
						setAnnouncements(resAnnouncements);
					}
				});
		}
	}, [programId, roleCode, numToDisplay, setAnnouncements]);

	return (
		<div id="news">
			<h1>Welcome!</h1>
			<p>
				Welcome to Kite Navigator! To return to the homepage at any time, click
				the Home button in the blue navigation bar at the top of your screen.
			</p>
			{announcements}
			<h2>Get Started</h2>
			<p>
				Navigator is designed to guide you through the process of adding and
				providing information about your student through online questionnaires
				so Navigator can assign a map to your student for instruction. These are
				the activities you’ll complete in Kite Navigator:
				{' '}
			</p>
			<ol>
				<li>Create a roster and add your students.</li>
				<li>Complete the Student Profile Survey.</li>
				<li>Complete the Student Writing Survey.</li>
				<li>Assign a map.</li>
				<li>Begin a new cycle.</li>
			</ol>
			<p>
				To begin, please select the Classes button in the blue navigation bar.
			</p>
		</div>
	);
};

export default connect(mapStateToProps)(News);
