import React, { useState, useEffect } from 'react';
import { Upload } from '@progress/kendo-react-upload';
import { saveAs, encodeBase64 } from '@progress/kendo-file-saver';
import { Input } from '@progress/kendo-react-inputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Label } from '@progress/kendo-react-labels';
import parse from 'csv-parse/lib/sync';
import axios from 'axios';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	races,
	disabilityCodes,
	languages,
	esolCodes,
	genders,
} from '../students/StudentLookups';

// Columns in the file should be in this order
// 'Attendance_State_Identifier',
// 'Attendance_Region_Identifier',
// 'Attendance_District_Identifier',
// 'Student_Legal_Last_Name',
// 'Student_Legal_First_Name',
// 'Student_Legal_Middle_Name',
// 'Generation_Code',
// 'Gender',
// 'Date_of_Birth',
// 'State_Student_Identifier',
// 'Current_School_year',
// 'Attendance_School_Program_Identifier',
// 'School_Entry_Date',
// 'District_Entry_Date',
// 'State_Entry_Date',
// 'Comprehensive_Race',
// 'Primary_Disability_Code',
// 'Hispanic_Ethnicity',
// 'ESOL_Participation_Code',
// 'Assessment_Program_1',
// 'Active_Start_Date',
// 'Active_End_Date',
// 'Current_Grade_Level',
// 'Accountability_District_Identifier',
// 'Accountability_School_Identifier',
// 'Local_Student_Identifier',
// 'Gifted_Student',
// 'First_Language',

// --CORE
// 'Attendance_State_Identifier',
// 'Attendance_Region_Identifier',
// Attendance_District_Identifier,
// Student_Legal_Last_Name,
// Student_Legal_First_Name,
// Student_Legal_Middle_Name,
// Generation_Code,
// Gender,
// Date_of_Birth,
// State_Student_Identifier,
// Current_School_year,
// Attendance_School_Program_Identifier,
// Comprehensive_Race,
// Primary_Disability_Code,
// Hispanic_Ethnicity,
// ESOL_Participation_Code,
// Assessment_Program_1,
// Active_Start_Date,
// Active_End_Date,
// Current_Grade_Level,

// --ATTRIBUTE
// School_Entry_Date,
// District_Entry_Date,
// State_Entry_Date,
// Accountability_District_Identifier,
// Accountability_School_Identifier,
// Local_Student_Identifier,
// Gifted_Student,
// First_Language,

const mapStateToProps = (state) => {
	const { authReducer } = state;
	return {
		isAuthenticated: authReducer.isAuthenticated,
		userObj: authReducer.userObj,
		programId: authReducer.assessmentProgram.id,
		roleCode: authReducer.role.code,
	};
};

const RosterUpload = (props) => {
	const [messages, setMessages] = useState([]);
	const [rosterName, setRosterName] = useState('');
	const [file, setFile] = useState(null);
	const [allTeachers, setAllTeachers] = useState([]);
	const [selectedTeacher, setSelectedTeacher] = useState({});
	const [canSave, setCanSave] = useState(false);
	const history = useHistory();
	const {
		isAuthenticated,
		userObj,
		programId,
		roleCode,
	} = props;

	if (!isAuthenticated) {
		history.push('/login');
	}

	useEffect(() => {
		const isTeacher = roleCode && !roleCode.includes('teacher');
		const teacherSelected = (selectedTeacher
			&& selectedTeacher.id !== undefined
			&& selectedTeacher.id !== null);
		const hasRosterName = rosterName.length > 0;
		if (isTeacher) {
			setCanSave(teacherSelected && hasRosterName);
		} else {
			setCanSave(hasRosterName);
		}
	}, [roleCode, rosterName.length, selectedTeacher]);

	useEffect(() => {
		if (roleCode && !roleCode.includes('teacher')) {
			axios.get(`/api/teachers/all/${programId}`, {
				params: {
					programId,
					roleCode,
				},
			}).then((resp) => {
				setAllTeachers(resp.data[0]?.v_teacher_json);
			});
		}
	}, [programId, roleCode]);

	const saveRoster = () => {
		const myRosterName = rosterName.trim();
		if (myRosterName.length > 0) {
			const roster = {
				rosterName: myRosterName,
				user: userObj.id,
				teachers: selectedTeacher.id ? [selectedTeacher.id] : [userObj.id],
			};
			if (file) {
				file.text().then((text) => {
					const records = parse(text, {
						columns: true,
						cast: (value, context) => {
							if (value === '') {
								return null;
							}
							if ([
								'Gender',
								'Comprehensive_Race',
								'ESOL_Participation_Code',
							].includes(context.column)) {
								return parseInt(value, 10);
							}
							if ([
								'Date_of_Birth',
								'School_Entry_Date',
								'District_Entry_Date',
								'State_Entry_Date',
								'Active_Start_Date',
								'Active_End_Date',
							].includes(context.column)) {
								const [monthStr, dayStr, yearStr] = value.split('/');
								return new Date(
									parseInt(yearStr, 10),
									parseInt(monthStr, 10) - 1,
									parseInt(dayStr, 10),
								);
							}
							return value;
						},
					});
					roster.students = records.map((record) => {
						const {
							Attendance_State_Identifier: attendanceStateIdentifier,
							Attendance_Region_Identifier: attendanceRegionIdentifier,
							Attendance_District_Identifier: attendanceDistrictIdentifier,
							Student_Legal_Last_Name: studentLegalLastName,
							Student_Legal_First_Name: studentLegalFirstName,
							Student_Legal_Middle_Name: studentLegalMiddleName,
							Generation_Code: generationCode,
							Gender: gender,
							Date_of_Birth: dateOfBirth,
							State_Student_Identifier: stateStudentIdentifier,
							Current_School_Year: currentSchoolYear,
							Attendance_School_Program_Identifier: attendanceSchoolProgramIdentifier,
							Comprehensive_Race: comprehensiveRace,
							Primary_Disability_Code: primaryDisabilityCode,
							Hispanic_Ethnicity: hispanicEthnicity,
							ESOL_Participation_Code: esolParticipationCode,
							Assessment_Program_1: assessmentProgram1,
							Active_Start_Date: activeStartDate,
							Active_End_Date: activeEndDate,
							Current_Grade_Level: currentGradeLevel,
							...attributes
						} = record;
						return {
							Attendance_State_Identifier: attendanceStateIdentifier,
							Attendance_Region_Identifier: attendanceRegionIdentifier,
							Attendance_District_Identifier: attendanceDistrictIdentifier,
							Student_Legal_Last_Name: studentLegalLastName,
							Student_Legal_First_Name: studentLegalFirstName,
							Student_Legal_Middle_Name: studentLegalMiddleName,
							Generation_Code: generationCode,
							Gender: gender,
							Date_of_Birth: dateOfBirth,
							State_Student_Identifier: stateStudentIdentifier,
							Current_School_Year: currentSchoolYear,
							Attendance_School_Program_Identifier: attendanceSchoolProgramIdentifier,
							Comprehensive_Race: comprehensiveRace,
							Primary_Disability_Code: primaryDisabilityCode,
							Hispanic_Ethnicity: hispanicEthnicity,
							ESOL_Participation_Code: esolParticipationCode,
							Assessment_Program_1: assessmentProgram1,
							Active_Start_Date: activeStartDate,
							Active_End_Date: activeEndDate,
							Current_Grade_Level: currentGradeLevel,
							attributes,
						};
					});
					axios.post('/api/roster', roster, {
						params: {
							programId,
							roleCode,
						},
					}).then((/* res */) => {
						setMessages([{ message: 'Successful upload' }]);
						setRosterName('');
						setFile(null);
					}).catch((err) => {
						const { response } = err;
						const { data } = response;
						if (data && data.length && data.length > 0 && data.constructor.name === 'Array') {
							setMessages(data);
						} else {
							setMessages([{ message: data || 'Unknown error' }]);
						}
					});
				});
			} else {
				roster.students = [];
				axios.post('/api/roster', roster, {
					params: {
						programId,
						roleCode,
					},
				}).then((/* res */) => {
					setMessages([{ message: 'Successful upload' }]);
					setRosterName('');
					setFile(null);
				}).catch((err) => {
					const { response } = err;
					const { data } = response;
					if (data && data.length && data.length > 0) {
						setMessages([{ message: JSON.stringify(data) }]);
					} else {
						setMessages([{
							message: JSON.stringify(data) || 'Unknown error',
						}]);
					}
				});
			}
		} else {
			setMessages([{ message: 'Roster name is required' }]);
		}
	};

	const renderTeacherSelect = () => {
		if (roleCode && !roleCode.includes('teacher')) {
			return (
				<div style={{ marginTop: '20px', marginBottom: '10px' }}>
					<Label
						className="requiredField"
						id="teacherlabel"
						editorId="teacherlabel"
						editorValue={selectedTeacher}
					>
						Teacher Name:
					</Label>
					<ComboBox
						style={{ width: '400px' }}
						data={allTeachers}
						value={selectedTeacher}
						textField="display_name"
						dataItemKey="id"
						onChange={(e) => {
							setSelectedTeacher(e.target.value);
						}}
					/>
				</div>
			);
		}
		return null;
	};

	const onAdd = (event) => {
		const myUploadFileInfo = event.affectedFiles[0];
		if (myUploadFileInfo.extension === '.csv') {
			setFile(myUploadFileInfo.getRawFile());
		} else {
			setMessages([{ message: 'Roster file must be a .csv file.' }]);
		}
	};

	const saveExampleCsv = (() => {
		const dataURI = `data:text/csv;base64,${encodeBase64('Attendance_State_Identifier,Attendance_Region_Identifier,Attendance_District_Identifier,Student_Legal_Last_Name,Student_Legal_First_Name,Student_Legal_Middle_Name,Generation_Code,Gender,Date_of_Birth,State_Student_Identifier,Current_School_Year,Attendance_School_Program_Identifier,School_Entry_Date,District_Entry_Date,State_Entry_Date,Comprehensive_Race,Primary_Disability_Code,Hispanic_Ethnicity,ESOL_Participation_Code,Assessment_Program_1,Active_Start_Date,Active_End_Date,Current_Grade_Level,Accountability_District_Identifier,Accountability_School_Identifier,Local_Student_Identifier,Gifted_Student,First_Language')}`;
		saveAs(dataURI, 'roster.csv');
	});
	return (
		<div className="rosters-container">
			<div>
				<button type="button" className="btn btn-primary" id="roster-back-button" primary={true} onClick={() => history.push('/rosters')}>
					Back to Rosters
				</button>
			</div>
			<div className="page-description-container">
				<div className="page-description-paragraph">
					Create a roster manually by entering a Roster Name and
					selecting the Save Roster button. We’ll then add the students one-by-one.
				</div>
				<div className="page-description-paragraph">OR</div>
				<div className="page-description-paragraph">
					If you’d like to upload multiple students at a time, download the Roster Template
					by selecting the Roster Template button. Fill out the spreadsheet,
					upload it below, and select Save Roster.
					Hint: Use the lookup values below to help you fill out the spreadsheet.
					To return to your roster and begin adding students, select the Back to Rosters button.
				</div>
			</div>
			<br />
			<span className="studentField">
				<Label
					className="requiredField"
					id="rosternamelabel"
					editorId="rosternamecontrol"
					editorValue={rosterName}
				>
					Roster Name:
				</Label>
				<br />
				<Input
					id="rosternamecontrol"
					ariaLabelledBy="rosternamelabel"
					value={rosterName}
					required={true}
					onChange={(e) => {
						setRosterName(e.target.value);
					}}
				/>
			</span>
			{renderTeacherSelect()}
			<Upload
				batch={false}
				multiple={false}
				autoUpload={false}
				onAdd={onAdd}
			/>
			{
				file ? (
					<div>
						Selected for upload:
						{` ${file.name}`}
					</div>
				) : undefined
			}
			<button type="button" className="btn btn-primary" onClick={saveRoster} primary disabled={!canSave}>Save Roster</button>
			<button type="button" className="btn btn-primary" onClick={saveExampleCsv}>Roster Template</button>
			<ul>
				{messages.map((message) => (
					<li key={message}>
						{
							message.message
						}
					</li>
				))}
			</ul>
			<p>
				Date format for upload spreadsheet date fields:
				<em>MM/DD/YYYY</em>
			</p>
			<PanelBar>
				<PanelBarItem
					title="Gender lookup values"
				>
					<Grid
						data={genders}
					>
						<Column field="id" title="CSV value" />
						<Column field="label" title="Meaning" />
					</Grid>
				</PanelBarItem>
				<PanelBarItem
					title="Comprehensive Race lookup values"
				>
					<Grid
						data={races}
					>
						<Column field="id" title="CSV value" />
						<Column field="label" title="Meaning" />
					</Grid>
				</PanelBarItem>
				<PanelBarItem
					title="Primary Disability Code lookup values"
				>
					<Grid
						data={disabilityCodes}
					>
						<Column field="id" title="CSV value" />
						<Column field="label" title="Meaning" />
					</Grid>
				</PanelBarItem>
				<PanelBarItem
					title="First Language lookup values"
				>
					<Grid
						data={languages}
					>
						<Column field="id" title="CSV value" />
						<Column field="label" title="Meaning" />
					</Grid>
				</PanelBarItem>
				<PanelBarItem
					title="ESOL Code lookup values"
				>
					<Grid
						data={esolCodes}
					>
						<Column field="id" title="CSV value" />
						<Column field="label" title="Meaning" />
					</Grid>
				</PanelBarItem>
			</PanelBar>
		</div>
	);
};
export default connect(mapStateToProps)(RosterUpload);
