import {
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
	SIGNOUT_SUCCESS,
	FORGOT_PWD_SUCCESS,
	RESET_PWD_SUCCESS,
	FORGOT_PWD_FAILURE,
	RESET_PWD_FAILURE,
	ASSESSMENT_PROGRAM_SET,
	ROLE_SET,
	NEW_PWD_SUCCESS,
	NEW_PWD_FAILURE,
} from './AuthTypes';

const defaultState = {
	isAuthenticated: false,
	userObj: {},
	assessmentProgram: {},
	role: {},
};

const authReducer = (state = defaultState, action) => {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return {
				...state,
				isAuthenticated: true,
				userObj: action.userObj,
				assessmentProgram: action.userObj.assessmentPrograms[0],
				role: action.userObj.assessmentPrograms[0].roles[0],
				errorMessage: null,
			};

		case ASSESSMENT_PROGRAM_SET:
			return {
				...state,
				assessmentProgram: action.assessmentProgram,
				role: action.assessmentProgram.roles[0],
			};
		case ROLE_SET:
			return {
				...state,
				role: action.role,
			};

		case LOGIN_FAILURE:
		case FORGOT_PWD_FAILURE:
		case RESET_PWD_FAILURE:
		case NEW_PWD_FAILURE:
			return {
				...state,
				isAuthenticated: false,
				userObj: {},
				assessmentProgram: {},
				role: {},
				errorMessage: action.errorMessage,
			};

		case SIGNOUT_SUCCESS:
		case FORGOT_PWD_SUCCESS:
		case RESET_PWD_SUCCESS:
		case NEW_PWD_SUCCESS:
			return {
				...state,
				isAuthenticated: false,
				userObj: {},
				assessmentProgram: {},
				role: {},
				errorMessage: null,
			};

		default:
			return state;
	}
};

export default authReducer;
