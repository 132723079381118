import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

const mapStateToProps = (state) => {
	const { authReducer } = state;
	return {
		programId: authReducer.assessmentProgram.id,
		roleCode: authReducer.role.code,
		permissions: authReducer.role.permission,
		userId: authReducer.userObj.id,
	};
};

const ClusterMediaGrid = (props) => {
	const [mediaData, setMediaData] = useState([]);
	const {
		mapAssignment,
		cluster,
		programId,
		roleCode,
	} = props;

	useEffect(() => {
		axios.get(`/api/map/data/${mapAssignment.map_ee}.${mapAssignment.grade_band}.${cluster}/${mapAssignment.cluster_level}`, {
			params: {
				programId,
				roleCode,
			},
		})
			.then((res) => {
				if (res.status === 200) {
					setMediaData(res.data.map((row) => {
						return {
							...row,
							level: row.isNeighborhoodMedia ? '' : mapAssignment.cluster_level,
						};
					}));
				}
			});
	}, [
		cluster,
		mapAssignment.cluster_level,
		mapAssignment.grade_band,
		mapAssignment.map_ee,
		programId,
		roleCode,
	]);

	return (
		<Grid
			data={mediaData}
		>
			<Column
				title="Resource"
				width="195"
				cell={(innerProps) => (
					<td>
						<a
							href={innerProps.dataItem.s3URL}
							rel="noreferrer"
							target="_blank"
						>
							{innerProps.dataItem.name}
						</a>
					</td>
				)}
			/>
			<Column field="filetype" title="Type" width="220" />
			<Column field="level" title="Cluster Level" width="220" />
		</Grid>
	);
};

export default connect(mapStateToProps)(ClusterMediaGrid);
