import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Input } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { toast } from 'react-toastify';
import StudentDetails from '../students/StudentDetails';
import StudentProfile from '../students/StudentProfile';
import RosterStudentActionsCell from './RosterStudentActionsCell';

const mapStateToProps = (state) => {
	const { authReducer } = state;
	return {
		programId: authReducer.assessmentProgram.id,
		roleCode: authReducer.role.code,
		permissions: authReducer.role.permission,
	};
};

const RosterStudents = (props) => {
	const {
		roster,
		programId,
		roleCode,
		nameChangeCallback,
		permissions,
		onDeleteRoster,
	} = props;
	const [gridData, setGridData] = useState([]);
	const [studentSelection, setStudentSelection] = useState(null);
	const [editingName, setEditingName] = useState(false);
	const [editedName, setEditedName] = useState('');
	const [soySurveyId, setSOYSurveyId] = useState(null);
	const [rosterDialogVisible, setRosterDialogVisible] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const fetchStudents = useCallback(() => {
		axios.get('/api/surveyDetails/configType/eoy_survey_details', {
			params: {
				programId,
				roleCode,
			},
		})
			.then((eoySurveyDetailsResponse) => {
				let eoySurveyId1 = '';
				let eoySurveyPrefixURL = '';
				if (eoySurveyDetailsResponse.status === 200 && eoySurveyDetailsResponse.data.length) {
					eoySurveyId1 = eoySurveyDetailsResponse.data[0].configuration.survey_id;
					const eoySurveyBaseURL = eoySurveyDetailsResponse.data[0].configuration.survey_base_url;
					const eoySurveyClientName = eoySurveyDetailsResponse.data[0].configuration.client_name;
					eoySurveyPrefixURL = `${eoySurveyBaseURL}/#uuid=${eoySurveyId1}&client=${eoySurveyClientName}&externalId=`;
				}
				axios.get('/api/surveyDetails/configType/soy_survey_details', {
					params: {
						programId,
						roleCode,
					},
				})
					.then((surveyDetailsResponse) => {
						let soySurveyId1 = '';
						let soySurveyPrefixURL = '';
						if (surveyDetailsResponse.status === 200 && surveyDetailsResponse.data.length) {
							soySurveyId1 = surveyDetailsResponse.data[0].configuration.survey_id;
							const soySurveyBaseURL = surveyDetailsResponse.data[0].configuration.survey_base_url;
							const soySurveyClientName = surveyDetailsResponse.data[0].configuration.client_name;
							soySurveyPrefixURL = `${soySurveyBaseURL}/#uuid=${soySurveyId1}&client=${soySurveyClientName}&externalId=enrollment`;
							setSOYSurveyId(soySurveyId1);
						}
						axios.get(`/api/roster/${roster.id}`, {
							params: {
								programId,
								roleCode,
							},
						})
							.then((res) => {
								if (res.status === 200 && res.data.length) {
									res.data = res.data.map((_item) => {
										const item = { ..._item };
										if (!item.survey_status) {
											item.survey_status = 'Not Started';
										} else if (item.survey_status === 'COMPLETE') {
											item.survey_status = 'Completed';
										} else if (item.survey_status === 'IN PROGRESS') {
											item.survey_status = 'In Progress';
										} else if (item.survey_status === 'READY TO SUBMIT') {
											item.survey_status = 'Ready to Submit';
										}
										if (soySurveyPrefixURL !== '') {
											item.soy_survey_url = soySurveyPrefixURL + item.enrollment_id;
											item.soy_survey_id = soySurveyId1;
										}
										if (eoySurveyPrefixURL !== '') {
											item.eoy_survey_url = eoySurveyPrefixURL + item.id;
										}
										return item;
									});
									setGridData(res.data);
									setIsLoading(false);
								} else {
									setGridData([]);
								}
							})
							.catch(() => {
								setGridData([]);
							});
					});
			});
	}, [setGridData, roster.id, programId, roleCode]);

	useEffect(() => {
		fetchStudents();
	}, [fetchStudents, setGridData, roster.id, studentSelection, programId, roleCode,
		soySurveyId]);

	const onEditName = () => {
		setEditingName(true);
		setEditedName(roster.rostername);
	};

	const onSaveName = () => {
		if (!editedName) {
			return;
		}
		axios.put(`/api/roster/${roster.id}`, {
			rosterName: editedName,
		}, {
			params: {
				programId,
				roleCode,
			},
		})
			.then((res) => {
				if (res.status === 200 && res.data.length) {
					setEditingName(false);
					setEditedName('');
					nameChangeCallback(roster.id);
				}
			});
	};

	const onSetEndOfYearSurvey = () => {
		axios.put(`/api/roster/${roster.id}`, {
			end_of_year_survey_status: 'ready',
		}, {
			params: {
				programId,
				roleCode,
			},
		})
			.then((res) => {
				if (res.status === 200 && res.data.length) {
					fetchStudents();
				}
			});
	};

	const editStudentClick = (id) => {
		setStudentSelection({ id, action: 'edit' });
	};

	const toggleRosterDelDialog = () => {
		setRosterDialogVisible(!rosterDialogVisible);
	};

	// If new student, show new student form
	if (studentSelection === 'new') {
		return (
			<StudentDetails
				inStudentId={null}
				inRosterId={roster.id}
				inRosterName={roster.rostername}
				setStudentSelection={setStudentSelection}
			/>
		);
	}

	// for future purposes
	// if (studentSelection === 'existing') {
	// 	return (
	// 		<StudentDetails
	// 			inStudentId={null}
	// 			inRosterId={roster.id}
	// 			inRosterName={roster.rostername}
	// 			setStudentSelection={setStudentSelection}
	// 		/>
	// 	);
	// }

	if (studentSelection?.action === 'edit') {
		return (
			<StudentDetails
				inStudentId={studentSelection.id}
				inRosterId={roster.id}
				inRosterName={roster.rostername}
				setStudentSelection={setStudentSelection}
			/>
		);
	}

	// If student selected, show student profile
	if (studentSelection?.id) {
		return (
			<StudentProfile
				studentId={studentSelection.id}
				studentName={`${studentSelection.student_legal_last_name}, ${studentSelection.student_legal_first_name}`}
				rosterName={roster.rostername}
				rosterId={roster.id}
				setStudentSelection={setStudentSelection}
			/>
		);
	}

	const soySurveyResponseDetails = (soyRequest) => {
		setIsLoading(true);
		axios.post(`/api/surveyResponseDetails/enrollmentId/${soyRequest.enrollment_id}/surveyId/${soyRequest.soy_survey_id}/type/SOY`, {}, {
			params: {
				programId,
				roleCode,
			},
		}).then((res) => {
			if (res.status === 200) {
				fetchStudents();
			}
		});
	};

	const takeSurveyClick = (soyRequest) => {
		setIsLoading(true);
		axios.get(`/api/surveyDetails/fetchCompletedStudentSurvey/enrollmentId/${soyRequest.enrollment_id}`, {
			params: {
				programId,
				roleCode,
			},
		}).then((res) => {
			if (res.status === 200) {
				fetchStudents();
			}
		})
			.catch((err) => {
				if (err) {
					toast.error('An unexpected error occurred while loading the survey');
				}
			});
	};

	// Default: show students grid
	return (
		<>
			<div className="roster-button-container">
				<button type="button" className="btn btn-primary bi bi-person-plus" onClick={() => setStudentSelection('new')} primary>
					&nbsp;
					New Student
				</button>
				{// Code below is placeholder for future improvement of a second button
				}
				{/* {' '}
				<Button onClick={() => setStudentSelection('existing')} primary>
					Add Existing Student
				</Button> */}
			</div>
			<div>
				<p className="roster-name-header">
					Students for
					{' '}
					{
						editingName
							? (
								<span>
									<Input
										label="Roster Name"
										required={true}
										value={editedName}
										onChange={(e) => setEditedName(e.target.value)}
									/>
									<button type="button" className="btn btn-primary" onClick={onSaveName}>Save Name</button>
								</span>
							)
							: (
								<span>
									{roster.rostername}
									{' '}
									<button type="button" className="btn btn-primary bi bi-pencil-square" onClick={onEditName} primary>
										&nbsp;
										Edit Name
									</button>
								</span>
							)
					}
				</p>
			</div>
			<div className="page-description-container">
				<div className="page-description-paragraph">
					1.
					<span className="k-icon k-i-edit" id="student-roster-description-icon" />
					Select this icon if you need to edit information about the student.
					For example, their first or last name.
				</div>
				<div className="page-description-paragraph">
					2.
					<span className="k-icon k-i-hyperlink-open" id="student-roster-description-icon" />
					If you see this icon next to your student in the table below,
					click on it to fill out the Student Profile Survey.
				</div>
				<div className="page-description-paragraph">
					3.
					<span className="bi bi-arrow-right-circle" id="student-roster-description-icon" />
					After you’ve submitted the Student Profile Survey,
					you’ll see this icon. Click on it to lock in your answers.
				</div>
				<div className="page-description-paragraph">
					4. After you’ve completed the Student Profile Survey,
					click on your student’s name to start the writing cycle!
				</div>
				<div className="page-description-paragraph">
					<i>(Hint: The only icon by your student in the table below will be the edit icon)</i>
				</div>
			</div>
			{
				permissions && permissions.some((perm) => perm.code === 'UI_ENABLE_EOY_SURVEY')
				&& gridData.some((dataItem) => dataItem.end_of_year_survey_status !== 'ready')
					? <button type="button" className="btn btn-primary" onClick={onSetEndOfYearSurvey}>Enable End of Year Survey</button>
					: null
			}
			<Grid
				data={gridData}
				onRowClick={(e) => {
					if (e.dataItem.soy_survey_status === 'COMPLETE') {
						setStudentSelection(e.dataItem);
					}
				}}
			>
				<Column
					title="Actions"
					width="195"
					cell={(innerProps) => (
						!isLoading ? (
							<RosterStudentActionsCell
								dataItem={innerProps.dataItem}
								takeSurveyClick={(e) => takeSurveyClick(innerProps.dataItem, e)}
								editStudentClick={(e) => editStudentClick(innerProps.dataItem.id, e)}
								soySurveyResponseClick={(e) => soySurveyResponseDetails(innerProps.dataItem, e)}
							/>
						) : (
							'Loading...'
						)
					)}
				/>
				<Column
					field="student_legal_last_name"
					title="Last Name"
					width="220"
					cell={(data) => (
						data.dataItem.soy_survey_status !== 'COMPLETE' ? (
							<td>
								{
									data.dataItem.student_legal_last_name
								}
							</td>
						) : (
							<td>
								<td className="student-linked-name">
									{
										data.dataItem.student_legal_last_name
									}
								</td>
							</td>
						)
					)}
				/>
				<Column
					field="student_legal_first_name"
					title="First Name"
					width="220"
					cell={(data) => (
						data.dataItem.soy_survey_status !== 'COMPLETE' ? (
							<td>
								{
									data.dataItem.student_legal_first_name
								}
							</td>
						) : (
							<td>
								<td className="student-linked-name">
									{
										data.dataItem.student_legal_first_name
									}
								</td>
							</td>
						)
					)}
				/>
				<Column
					field="learning_map_node_clusters"
					title="Learning Map Node Clusters"
					cell={(data) => (
						<td>
							{
								data.dataItem.learning_map_node_clusters.join('\n')
							}
						</td>
					)}
				/>
				<Column field="communicative_independence_level" title="Level of Communicative Independence" />
				<Column field="survey_status" title="Writing Status" />
			</Grid>
			<div>
				<button type="button" className="btn btn-danger bi bi-trash" onClick={toggleRosterDelDialog} primary style={{ backgroundColor: 'red', marginBottom: '10px', marginTop: '10px' }}>
					&nbsp;
					Delete Roster
				</button>
				{rosterDialogVisible && (
					<Dialog title="Please confirm" onClose={toggleRosterDelDialog}>
						<p style={{ margin: '25px', textAlign: 'center' }}>
							Are you sure you want to delete this roster?
						</p>
						<DialogActionsBar>
							<button type="button" className="btn btn-primary" onClick={toggleRosterDelDialog}>
								No
							</button>
							<button type="button" className="btn btn-primary" onClick={onDeleteRoster}>
								Yes
							</button>
						</DialogActionsBar>
					</Dialog>
				)}
			</div>
		</>
	);
};

export default connect(mapStateToProps)(RosterStudents);
