/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import axios from 'axios';
import { SignOutButton } from 'ats-react-login';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { receiveSignOut, signOutError } from '../auth/AuthActions';
import RoleSelect from '../role-select/RoleSelect';
import Logo from '../media/kite_logo.svg';
import store from '../store/store';
import NavBar from './navigation/NavBar';
import './AppHeader.css';

const AppHeader = (props) => {
	const { menuItems } = props;
	const { userObj } = store.getState().authReducer;
	const currentLocation = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const menuSelect = (e) => {
		history.push(e.item.data.route);
	};

	return (
		<div className="app-header-grid-container">
			<div className="application-logo">
				<img src={Logo} alt="" />
			</div>
			<div className="application-name">
				Navigator
			</div>
			<div className="role-switch-controls">
				<RoleSelect />
			</div>
			{/* <Menu
				onSelect={menuSelect}
				className="nav-bar"
			>
				{
					menuItems.map((item) => {
						return (
							<MenuItem
								key={item.text}
								text={item.text}
								data={item.data}
								cssClass={
									item.data.route === currentLocation.pathname
										? 'header-menu-item selected-menu-option'
										: 'header-menu-item'
								}
							/>
						);
					})
				}
			</Menu> */}
			<div className="nav-bar">
				<NavBar />
			</div>
		</div>
	);
};
export default AppHeader;
