import GET_MEMBERS from './MemberTypes';

const defaultState = {
	memberList: [],
};

const memberReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_MEMBERS:
			return {
				...state,
				memberList: action.data,
			};
		default:
			return state;
	}
};

export default memberReducer;
