import React from 'react';

const RosterStudentActionsCell = (props) => {
	const {
		editStudentClick, soySurveyResponseClick, dataItem, takeSurveyClick,
	} = props;
	const displaySOYSurveyDetails = dataItem.soy_survey_status !== 'COMPLETE';
	const inProgress = dataItem.soy_survey_status === 'IN PROGRESS';
	const complete = dataItem.soy_survey_status === 'COMPLETE';
	return (
		<td>
			<button
				type="button"
				className="btn"
				onClick={editStudentClick}
				aria-label="Edit Student"
				title="Edit Student"
			>
				<span className="k-icon k-i-edit" />
			</button>
			{
				dataItem.end_of_year_survey_status === 'ready' && dataItem.eoy_survey_url
					? (
						<a
							href={dataItem.eoy_survey_url}
							className="k-button"
							aria-label="End Of Year Survey"
							title="End Of Year Survey"
						>
							<span className="k-icon k-i-hyperlink-open" />
						</a>
					)
					: null
			}
			{
				displaySOYSurveyDetails
					? (
						<>
							<a
								href={dataItem.soy_survey_url}
								className="k-button"
								aria-label="Student Profile Survey"
								title="Student Profile Survey"
								target="_blank"
								rel="noreferrer"
								onClick={takeSurveyClick}
							>
								<span className="k-icon k-i-hyperlink-open" />
							</a>

							{
								complete || inProgress ? (
									<button
										type="button"
										className="btn"
										onClick={soySurveyResponseClick}
										title="Lock in Your Answers"
									>
										<i className="bi bi-arrow-right-circle" />
									</button>
								) : null
							}
						</>
					)
					: null
			}
		</td>
	);
};

export default RosterStudentActionsCell;
