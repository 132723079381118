import axios from 'axios';
import store from './store';
import GET_MEMBERS from './MemberTypes';

function dispatchGetMembers(data) {
	return {
		type: GET_MEMBERS,
		data,
	};
}

const getMembers = () => async (dispatch) => {
	const { assessmentProgram, role } = store.getState().authReducer;
	const { data } = await axios.get('/api/member', {
		params: {
			programId: assessmentProgram.id,
			roleCode: role.code,
		},
	});
	dispatch(dispatchGetMembers(data));
};

export default getMembers;
