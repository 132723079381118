import React from 'react';
import Modal from 'react-bootstrap/Modal';

const DeleteModal = ({
	show, setShow, deleteHandler, item,
}) => {
	return (
		<>
			<Modal show={show} backdrop="static" centered size="lg">
				<Modal.Body>
					<div className="form-group row  d-flex">
						<label className="col justify-center">Would you like to delete this?</label>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-danger btn-sm text-white" onClick={() => setShow(false)}>
						Close
					</button>
					<button
						type="button"
						className="btn btn-primary btn-sm text-white"
						onClick={() => {
							deleteHandler(item.dataItem);
							setShow(false);
						}}
					>
						Delete
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default DeleteModal;
