/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Input, RadioGroup } from '@progress/kendo-react-inputs';
import { Upload } from '@progress/kendo-react-upload';
import './Resources.css';

const mapStateToProps = (state) => {
	const { authReducer } = state;
	return {
		isAuthenticated: authReducer.isAuthenticated,
		programId: authReducer.assessmentProgram.id,
		roleCode: authReducer.role.code,
		permissions: authReducer.role.permission,
		userId: authReducer.userObj.id,
	};
};

const resourceTypes = [
	{ label: 'URL', value: 'url' },
	{ label: 'File', value: 'file' },
];

const Resources = (props) => {
	const [gridData, setGridData] = useState([]);
	const [uploadModalVisible, setUploadModalVisible] = useState(false);
	const [uploadDescription, setUploadDescription] = useState('');
	const [uploadType, setUploadType] = useState(resourceTypes[0].value);
	const [urlLocation, setUrlLocation] = useState('');
	const history = useHistory();

	const {
		programId,
		roleCode,
		permissions,
		userId,
		isAuthenticated,
	} = props;

	// If we're not authenticated, start on the login page.
	if (!isAuthenticated) {
		history.push('/login');
	}

	// Update the contents of the grid from the server (run after any data actions)
	const getGridData = useCallback(() => {
		axios.get('/api/resource', {
			params: {
				programId,
				roleCode,
			},
		})
			.then((result) => {
				setGridData(result.data);
			})
			.catch(() => {
				// Something went wrong. Zero everything out!
				setGridData([]);
			});
	}, [setGridData, programId, roleCode]);

	// Expire the file on the server when the trashcan icon is pressed
	const deleteResource = useCallback((dataItem) => {
		axios.put(`/api/resource/expire/${dataItem.id}`, {}, {
			params: {
				programId,
				roleCode,
			},
		})
			.then(() => {
				getGridData();
			});
	}, [getGridData, programId, roleCode]);

	// Create new URL resource
	const urlSubmit = () => {
		const axiosParams = {
			url: '/api/resource',
			data: {
				description: uploadDescription,
				url: urlLocation,
			},
			params: {
				programId,
				roleCode,
				userId,
			},
			method: 'post',
		};

		axios(axiosParams);

		// Close and reset modal
		setUploadDescription('');
		setUrlLocation('');
		setUploadType(resourceTypes[0].value);
		setUploadModalVisible(false);
		getGridData();
	};

	// On component load, get data from the server
	useEffect(() => {
		getGridData();
	}, [getGridData]);

	return (
		<div className="program-resources-container">
			<Grid
				data={gridData}
			>
				<Column
					field="created_date"
					title="Date"
					cell={(data) => (
						<td>
							{new Date(data.dataItem.created_date).toDateString()}
						</td>
					)}
				/>
				<Column
					cell={(data) => (
						<td>
							<a
								href={data.dataItem.url}
								rel="noreferrer"
								target="_blank"
							>
								{data.dataItem.filename ? data.dataItem.filename : data.dataItem.url}
							</a>
						</td>
					)}
					title="Materials"
				/>
				<Column field="description" title="Description" />
				{ // Users with permission to delete items can do so here:
					permissions && permissions.filter((perm) => perm.code === 'EDIT_RESOURCE').length
						? (
							<Column cell={(data) => (
								<td>
									<button
										type="button"
										icon="delete"
										className="btn bi bi-trash"
										onClick={() => deleteResource(data.dataItem)}
									/>

								</td>
							)}
							/>
						)
						: null
				}
			</Grid>
			{ // Users with permission to add resources can do so here:
				permissions && permissions.filter((perm) => perm.code === 'ADD_RESOURCE').length
					? (
						<button
							type="button"
							className="btn btn-light"
							primary={true}
							onClick={() => setUploadModalVisible(true)}
						>
							+ Add
						</button>
					)
					: null
			}
			{ // This modal code will only display when the user needs to upload a new resource
				uploadModalVisible
				&& (
					<Dialog
						title="Add New Resource"
						onClose={() => setUploadModalVisible(false)}
					>
						<Input
							label="Description"
							required={true}
							value={uploadDescription}
							onChange={(e) => {
								if (e.target.value?.length < 200) {
									setUploadDescription(e.target.value);
								}
							}}
						/>
						<RadioGroup
							data={resourceTypes}
							disabled={false}
							value={uploadType}
							onChange={(e) => setUploadType(e.value)}
							layout="horizontal"
							style={{ padding: '10px 0' }}
						/>
						{
							uploadType === 'url'
								? (
									<>
										<Input
											label="Location"
											type="url"
											title="Don't forget the protocol! For most addresses, this is 'https://'"
											required={true}
											value={urlLocation}
											onChange={(e) => setUrlLocation(e.target.value)}
										/>
										<br />
										<button
											type="button"
											className="btn"
											primary={true}
											disabled={(uploadDescription.length <= 0 || urlLocation.length <= 6)}
											onClick={urlSubmit}
										>
											Add
										</button>
									</>
								)
								: (
									<Upload
										batch={false}
										multiple={false}
										autoUpload={true}
										disabled={(uploadDescription.length <= 0)}
										restrictions={{
											allowedExtensions: [
												'.jpg', '.png', '.mp3', '.mp4', '.jpeg', '.gif',
												'.svg', '.pdf', '.docx', '.doc',
											],
											maxFileSize: 50000000,
										}}
										onBeforeUpload={(e) => {
											e.additionalData.fileName = e.files[0].name;
											e.additionalData.fileType = e.files[0].extension;
											e.additionalData.description = uploadDescription;
										}}
										saveUrl={`/api/resource?programId=${programId}&roleCode=${roleCode}&userId=${userId}`}
										onStatusChange={() => {
											setUploadDescription('');
											setUrlLocation('');
											setUploadType(resourceTypes[0].value);
											setUploadModalVisible(false);
											getGridData();
										}}
									/>
								)
						}
					</Dialog>
				)
			}
		</div>
	);
};
export default connect(mapStateToProps)(Resources);
