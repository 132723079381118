/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Input } from '@progress/kendo-react-inputs';
import { Upload } from '@progress/kendo-react-upload';
import { connect } from 'react-redux';
import DeleteModal from '../modals/DeleteModal';

const mapStateToProps = (state) => {
	const { authReducer } = state;
	return {
		programId: authReducer.assessmentProgram.id,
		roleCode: authReducer.role.code,
	};
};

const WritingSample = (props) => {
	const [gridData, setGridData] = useState([]);
	const [uploadModalVisible, setUploadModalVisible] = useState(false);
	const [uploadDescription, setUploadDescription] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [item, setItem] = useState({});

	const { cycleId, programId, roleCode } = props;

	// Update the contents of the grid from the server (run after any data actions)
	const getGridData = useCallback(() => {
		axios.get(`/api/sample/cycle/${cycleId}`, {
			params: {
				programId,
				roleCode,
			},
		})
			.then((result) => {
				setGridData(result.data);
			})
			.catch(() => {
				// Something went wrong. Zero everything out!
				setGridData([]);
			});
	}, [setGridData, cycleId, programId, roleCode]);

	// Expire the file on the server when the trashcan icon is pressed
	const deleteSample = useCallback((dataItem) => {
		axios.put(`/api/sample/expire/${dataItem.id}`, {}, {
			params: {
				programId,
				roleCode,
			},
		})
			.then(() => {
				getGridData();
			});
	}, [getGridData, programId, roleCode]);

	// On component load, get data from the server
	useEffect(() => {
		getGridData();
	}, [getGridData]);

	return (
		<div>
			<Grid
				data={gridData}
			>
				<Column
					field="created_date"
					title="Date"
					cell={(data) => (
						<td>
							{new Date(data.dataItem.created_date).toDateString()}
						</td>
					)}
				/>
				<Column
					cell={(data) => (
						<td>
							<a
								href={data.dataItem.url}
								rel="noreferrer"
								target="_blank"
							>
								{data.dataItem.filename}
							</a>
						</td>
					)}
					title="Materials"
				/>
				<Column field="description" title="Description" />
				<Column cell={(data) => (
					<td>
						<button
							type="button"
							className="btn btn-primary"
							icon="delete"
							onClick={() => {
								setShowModal(!showModal);
								setItem(data);
							}}
						>
							Delete Sample
						</button>
					</td>
				)}
				/>
			</Grid>
			<button
				type="button"
				className="btn btn-primary"
				primary={true}
				onClick={() => setUploadModalVisible(true)}
			>
				+ Upload File
			</button>
			{ // This modal code will only display when the user needs to upload a new sample
				uploadModalVisible
				&& (
					<Dialog
						title="Upload New File"
						onClose={() => setUploadModalVisible(false)}
					>
						<Input
							label="Description"
							required={true}
							value={uploadDescription}
							onChange={(e) => {
								if (e.target.value?.length < 200) {
									setUploadDescription(e.target.value);
								}
							}}
						/>
						<Upload
							batch={false}
							multiple={false}
							autoUpload={true}
							disabled={(uploadDescription.length <= 0)}
							restrictions={{
								allowedExtensions: [
									'.jpg', '.png', '.mp3', '.mp4', '.jpeg', '.gif',
									'.svg', '.pdf', '.docx', '.doc',
								],
								maxFileSize: 50000000,
							}}
							onBeforeUpload={(e) => {
								e.additionalData.fileName = e.files[0].name;
								e.additionalData.fileType = e.files[0].extension;
								e.additionalData.description = uploadDescription;
							}}
							saveUrl={`/api/sample/${cycleId}?programId=${programId}&roleCode=${roleCode}`}
							onStatusChange={() => {
								setUploadDescription('');
								setUploadModalVisible(false);
								getGridData();
							}}
						/>
					</Dialog>
				)
			}
			<DeleteModal
				show={showModal}
				setShow={setShowModal}
				deleteHandler={deleteSample}
				item={item}
			/>
		</div>
	);
};
export default connect(mapStateToProps)(WritingSample);
