import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import RosterStudents from './RosterStudents';
import './Rosters.css';

const mapStateToProps = (state) => {
	const { authReducer } = state;
	return {
		isAuthenticated: authReducer.isAuthenticated,
		programId: authReducer.assessmentProgram.id,
		roleCode: authReducer.role.code,
		userObj: authReducer.userObj,
		permissions: authReducer.role.permission,
	};
};

const Rosters = (props) => {
	const {
		userObj, programId, roleCode, permissions, isAuthenticated,
	} = props;
	let { location: selectedTeacher } = props;
	const [gridData, setGridData] = useState([]);
	const [rosterSelection, setRosterSelection] = useState(null);
	const history = useHistory();

	// If we're not authenticated, start on the login page.
	if (!isAuthenticated) {
		history.push('/login');
	}

	// Add header for teacher, if we came from there
	selectedTeacher = selectedTeacher.state?.length
		? selectedTeacher.state[0]
		: false;
	const teacherHeader = selectedTeacher ? (
		<p className="roster-teacher-header">
			Classes for
			{' '}
			{selectedTeacher.first_name}
			{' '}
			{selectedTeacher.last_name}
			:
		</p>
	) : null;

	const onDeleteRoster = () => {
		axios
			.put(
				`/api/roster/${rosterSelection.id}/delete`,
				{},
				{
					params: {
						programId,
						roleCode,
					},
				},
			)
			.then(() => {
				setRosterSelection(null);
			})
			.catch(() => {
				setRosterSelection(null);
			});
	};

	useEffect(() => {
		const userId = selectedTeacher ? selectedTeacher.id : userObj.id;

		if (userId && !rosterSelection) {
			axios
				.get(`/api/roster/user/${userId}`, {
					params: {
						programId,
						roleCode,
					},
				})
				.then((res) => {
					if (res.status === 200 && res.data.length) {
						setGridData(res.data);
					} else {
						setGridData([]);
					}
				})
				.catch(() => {
					setGridData([]);
				});
		}
	}, [
		selectedTeacher,
		setGridData,
		userObj,
		programId,
		roleCode,
		rosterSelection,
	]);
	const onRosterNameChange = useCallback(
		(rosterId) => {
			const userId = selectedTeacher ? selectedTeacher.id : userObj.id;
			axios
				.get(`/api/roster/user/${userId}`, {
					params: {
						programId,
						roleCode,
					},
				})
				.then((res) => {
					if (res.status === 200 && res.data.length) {
						setGridData(res.data);
						setRosterSelection(
							res.data.find((roster) => roster.id === rosterId),
						);
					} else {
						setGridData([]);
					}
				})
				.catch(() => {
					setGridData([]);
				});
		},
		[selectedTeacher, setGridData, userObj, programId, roleCode],
	);

	const canAddRoster = permissions
    && permissions.filter((perm) => perm.code === 'ADD_ROSTER').length;
	// Default: Show rosters grid
	if (!rosterSelection) {
		return (
			<div className="rosters-container">
				{canAddRoster ? (
					<div className="roster-button-container">
						<h4>
							To create your classroom roster, select the New Roster button in the
							upper right corner of your screen.
							{' '}
						</h4>
						<div className="roster-button">
							<button
								type="button"
								className="btn btn-light bi bi-folder-plus"
								primary={true}
								onClick={() => history.push('/rosterupload')}
							>
              &nbsp; New Roster
							</button>
						</div>
					</div>
				) : null}
				{teacherHeader}
				<Grid
					data={gridData}
					onRowClick={(e) => setRosterSelection(e.dataItem)}
				>
					<Column
						field="rostername"
						title="Class Name"
						className="roster-name roster-name-header"
					/>
					<Column field="numstudents" title="# of Students" />
					<Column field="other" title="Other Information" />
				</Grid>
			</div>
		);
	}
	// Otherwise, pass on the selected roster
	return (
		<div className="rosters-container">
			<button
				type="button"
				className="btn btn-light breadcrumb"
				primary={true}
				onClick={() => setRosterSelection(null)}
			>
				Rosters
			</button>
			<RosterStudents
				roster={rosterSelection}
				onDeleteRoster={onDeleteRoster}
				nameChangeCallback={onRosterNameChange}
			/>
		</div>
	);
};

export default connect(mapStateToProps)(Rosters);
