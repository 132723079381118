import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import './NotificationItem.css';

const mapStateToProps = (state) => {
	const { authReducer } = state;
	return {
		programId: authReducer.assessmentProgram.id,
		roleCode: authReducer.role.code,
	};
};

const NotificationItem = (props) => {
	const {
		dataItem, markReadCallback, programId, roleCode,
	} = props;

	const handleReadButtonClick = () => {
		axios.put(`/api/notification/${dataItem.id}`, {}, {
			params: {
				programId,
				roleCode,
			},
		})
			.then(() => {
				markReadCallback();
			})
			.catch(() => {
			});
	};

	return (
		<div className="notificationItem">
			<span className={dataItem.read_status ? 'read' : 'unread'}>{dataItem.message}</span>
			{
				!dataItem.read_status
					? <button type="button" className="btn btn-light" style={{ float: 'right' }} onClick={handleReadButtonClick}>Mark Read</button>
					: undefined
			}
			<br />
			<span>{new Date(dataItem.created_date).toLocaleDateString()}</span>
		</div>
	);
};

export default connect(mapStateToProps)(NotificationItem);
