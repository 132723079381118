const genders = [
	{ id: 0, label: 'Female' },
	{ id: 1, label: 'Male' },
	{ id: 2, label: 'Non-binary' },
];

const races = [
	{ id: 1, label: 'White' },
	{ id: 2, label: 'African American' },
	{ id: 4, label: 'Asian' },
	{ id: 5, label: 'American Indian' },
	{ id: 6, label: 'Alaska Native' },
	{ id: 7, label: 'Two or More Races' },
	{ id: 8, label: 'Native Hawaiian or Pacific Islander' },
];

const disabilityCodes = [
	{ id: 'AM', label: 'Autism' },
	{ id: 'DB', label: 'Deaf/blindness' },
	{ id: 'DD', label: 'Developmentally delayed (ages 3-9 only)' },
	{ id: 'ED', label: 'Emotional disturbance' },
	{ id: 'HI', label: 'Hearing impairment' },
	{ id: 'ID', label: 'Intellectual disability' },
	{ id: 'LD', label: 'Specific learning disability' },
	{ id: 'MD', label: 'Multiple disabilities' },
	{ id: 'EI', label: 'Eligible individual' },
	{ id: 'DA', label: 'Decline to answer' },
	{ id: 'ND', label: 'No disability' },
	{ id: 'OH', label: 'Other health impairment' },
	{ id: 'OI', label: 'Orthopedic impairment' },
	{ id: 'SL', label: 'Speech or language impairment' },
	{ id: 'TB', label: 'Traumatic brain injury' },
	{ id: 'VI', label: 'Visual impairment' },
	{ id: 'WD', label: 'Documented disability' },
];

const languages = [
	{ id: '', label: 'English' },
	{ id: '1', label: 'Chinese (Mandarin or Cantonese)' },
	{ id: '2', label: 'Dinka (Sudanese)' },
	{ id: '3', label: 'French' },
	{ id: '4', label: 'High German' },
	{ id: '5', label: 'Hmong' },
	{ id: '6', label: 'Khmer (Cambodian)' },
	{ id: '7', label: 'Korean' },
	{ id: '8', label: 'Lao' },
	{ id: '10', label: 'Filipino or Tagalog (Philippines)' },
	{ id: '11', label: 'Russian' },
	{ id: '13', label: 'Spanish' },
	{ id: '14', label: 'Vietnamese' },
	{ id: '15', label: 'Arabic' },
	{ id: '16', label: 'Other' },
	{ id: '17', label: 'Somali' },
	{ id: '18', label: 'Thai' },
	{ id: '19', label: 'Portuguese' },
	{ id: '20', label: 'Farsi (Iranian)' },
	{ id: '21', label: 'Chuukese (e.g., Marshall Island, Micronesian)' },
	{ id: '22', label: 'Bosnian' },
	{ id: '23', label: 'Burmese' },
	{ id: '24', label: 'Hindi' },
	{ id: '25', label: 'Urdu' },
	{ id: '26', label: 'Swahili' },
	{ id: '27', label: 'Nepali' },
	{ id: '28', label: 'American Sign Language (ASL)' },
	{ id: '29', label: 'Serb' },
	{ id: '30', label: 'Croatian' },
	{ id: '31', label: 'Turkish' },
	{ id: '32', label: 'Karen languages (e.g., Burma, Myanmar)' },
	{ id: '33', label: 'Haitian/Haitian Creole' },
	{ id: '34', label: 'Gujarati' },
	{ id: '35', label: 'Punjabi' },
	{ id: '36', label: 'Pashto' },
	{ id: '37', label: 'Dari' },
	{ id: '38', label: 'Quiche' },
	{ id: '39', label: 'Mam' },
	{ id: '40', label: 'Ilokano' },
	{ id: '41', label: 'Visayan' },
	{ id: '42', label: 'Low German' },
	{ id: '43', label: 'Other signed language' },
	{ id: '44', label: 'English - with other language background' },
	{ id: '45', label: 'Native American Languages' },
];

const esolCodes = [
	{ id: 0, label: 'Neither an ESOL-eligible student nor an ESOL-monitored student' },
	{ id: 1, label: 'Title III Funded' },
	{ id: 2, label: 'State ESOL/bilingual funded' },
	{ id: 3, label: 'Both Title III and state ESOL/bilingual funded' },
	{ id: 4, label: 'Monitored ESOL student' },
	{ id: 5, label: 'Eligible for ESOL program based on an English language proficiency test but not currently receiving ESOL program services' },
	{ id: 6, label: 'Receives ESOL services and not funded with Title III and/or state ESOL funding' },
];

const yesNo = [
	{ id: 'Yes', label: 'Yes' },
	{ id: 'No', label: 'No' },
];

const trueFalse = [
	{ id: 'TRUE', label: 'Yes' },
	{ id: 'FALSE', label: 'No' },
];

const grades = [
	{ id: 'K', label: 'K' },
	{ id: '1', label: '1' },
	{ id: '2', label: '2' },
	{ id: '3', label: '3' },
	{ id: '4', label: '4' },
	{ id: '5', label: '5' },
	{ id: '6', label: '6' },
];

const assessmentPrograms = [
	{ id: 'SWIM', label: 'SWIM' },
];

const schoolYears = [
	{ id: '2021', label: '2021-2022' },
	{ id: '2022', label: '2022-2023' },
];

const stateDistrictSchools = [
	{
		id: 'KN',
		stateName: 'Kansas',
		districts: [
			{
				id: '0',
				districtName: 'Crest',
				schools: [
					{
						id: '0',
						schoolName: 'Crest Elementary',
					}, {
						id: '1',
						schoolName: 'Crest Middle School',
					}, {
						id: '2',
						schoolName: 'Crest High School',
					},
				],
			}, {
				id: '1',
				districtName: 'Inman',
				schools: [
					{
						id: '3',
						schoolName: 'Inman Elementary',
					}, {
						id: '4',
						schoolName: 'Inman Middle School',
					}, {
						id: '5',
						schoolName: 'Inman High School',
					},
				],
			}, {
				id: '2',
				districtName: 'Lewis',
				schools: [
					{
						id: '6',
						schoolName: 'Lewis Elementary',
					}, {
						id: '7',
						schoolName: 'Lewis Middle School',
					}, {
						id: '8',
						schoolName: 'Lewis High School',
					},
				],
			},
		],
	}, {
		id: '1',
		stateName: 'Alaska',
		districts: [
			{
				id: '3',
				districtName: 'Anchorage',
				schools: [
					{
						id: '9',
						schoolName: 'Anchorage Elementary',
					}, {
						id: '10',
						schoolName: 'Anchorage Middle School',
					}, {
						id: '11',
						schoolName: 'Anchorage High School',
					},
				],
			}, {
				id: '4',
				districtName: 'Copper River',
				schools: [
					{
						id: '12',
						schoolName: 'Copper River Elementary',
					}, {
						id: '13',
						schoolName: 'Copper River Middle School',
					}, {
						id: '14',
						schoolName: 'Copper River High School',
					},
				],
			}, {
				id: '5',
				districtName: 'Galena',
				schools: [
					{
						id: '15',
						schoolName: 'Galena Elementary',
					}, {
						id: '16',
						schoolName: 'Galena Middle School',
					}, {
						id: '17',
						schoolName: 'Galena High School',
					},
				],
			},
		],
	}, {
		id: '2',
		stateName: 'New York',
		districts: [
			{
				id: '4',
				districtName: 'Long Island',
				schools: [
					{
						id: '18',
						schoolName: 'Long Island Elementary',
					}, {
						id: '19',
						schoolName: 'Long Island Middle School',
					}, {
						id: '20',
						schoolName: 'Long Island High School',
					},
				],
			}, {
				id: '5',
				districtName: 'Baldwin',
				schools: [
					{
						id: '21',
						schoolName: 'Baldwin Elementary',
					}, {
						id: '22',
						schoolName: 'Baldwin Middle School',
					}, {
						id: '23',
						schoolName: 'Baldwin High School',
					},
				],
			}, {
				id: '6',
				districtName: 'Oceanside',
				schools: [
					{
						id: '24',
						schoolName: 'Oceanside Elementary',
					}, {
						id: '25',
						schoolName: 'Oceanside Middle School',
					}, {
						id: '26',
						schoolName: 'Oceanside High School',
					},
				],
			},
		],
	},
];

export {
	genders,
	races,
	disabilityCodes,
	languages,
	esolCodes,
	yesNo,
	trueFalse,
	grades,
	assessmentPrograms,
	schoolYears,
	stateDistrictSchools,
};
