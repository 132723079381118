/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { CKEditorBase } from 'kite-react-items';
import PropTypes from 'prop-types';

const toolbarItems = [
	'heading',
	'|',
	'bold',
	'italic',
	'underline',
	'strikethrough',
	'subscript',
	'superscript',
	'removeFormat',
	'|',
	'bulletedList',
	'numberedList',
	'outdent',
	'indent',
	'alignment',
	'|',
	'fontBackgroundColor',
	'fontColor',
	'fontFamily',
	'fontSize',
	'wproofreader',
	'|',
	'undo',
	'redo',
	'horizontalLine',
	'pageBreak',
	'blockQuote',
	'findAndReplace',
	'|',
	'specialCharacters',
	'insertTable',
	'|',
	'imageStyle:inline',
	'imageStyle:block',
	'imageStyle:side',
	'toggleImageCaption',
	'imageTextAlternative',
	'|',
	'insertResponse',
];

const CKEditor = ({
	onReady = () => undefined,
	config = {},
	onChange = () => undefined,
	...props
}) => {
	const [editor, setEditor] = useState(null);

	const handleEditorReady = (ckeditor) => {
		if (!editor) setEditor(ckeditor);
		return onReady(ckeditor);
	};

	// Pass through configuration;
	const configuration = {
		toolbar: {
			items: toolbarItems,
		},
		...config,
	};

	return (
		<>
			<CKEditorBase
				config={configuration}
				onReady={handleEditorReady}
				onChange={onChange}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			/>
		</>
	);
};

CKEditor.propTypes = {
	onReady: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	config: PropTypes.object,
	onChange: PropTypes.func,
};

export default CKEditor;
