/* eslint-disable no-debugger */
import React, { useEffect, useState, useCallback } from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import axios from 'axios';
import './MapAssignment.css';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
	const { authReducer } = state;
	return {
		programId: authReducer.assessmentProgram.id,
		roleCode: authReducer.role.code,
		permissions: authReducer.role.permission,
		userId: authReducer.userObj.id,
	};
};

const gradeBands = [
	{ id: 'K2', label: 'Kindergarten - Grade 2' },
	{ id: '24', label: 'Grade 2 - Grade 4' },
	{ id: '46', label: 'Grade 4 - Grade 6' },
];

const writingLevels = [
	{ id: 'C', label: 'Conventional' },
	{ id: 'T', label: 'Transitional' },
	{ id: 'E', label: 'Emergent' },
];

const MapAssignment = (props) => {
	const {
		cycleId,
		reload,
		programId,
		roleCode,
		userId,
		mapAssignment,
		permissions,
		suggestedVal,
		onSetWritingLevel,
	} = props;
	const [levels, setLevels] = useState([]);
	const [cluster, setCluster] = useState('X');
	const [selectedWritingLevel, setSelectedWritingLevel] = useState(
		writingLevels.find((item) => item.id === cluster),
	);
	const [selectedLevel, setSelectedLevel] = useState(mapAssignment.cluster_level);
	const [selectedGradeBand, setSelectedGradeBand] = useState(
		gradeBands.find((item) => item.id === mapAssignment.grade_band),
	);

	const updateComboBoxes = useCallback(() => {
		onSetWritingLevel(selectedWritingLevel);
		if (!selectedWritingLevel) {
			if (suggestedVal === 'Conventional') {
				setCluster('C');
			} else if (suggestedVal === 'Transitional') {
				setCluster('T');
			} else if (suggestedVal === 'Emergent') {
				setCluster('E');
			}
		} else {
			setCluster(selectedWritingLevel.id);
		}
		if (selectedGradeBand && selectedGradeBand.id) {
			axios.get(`/api/map/data/${mapAssignment.map_ee}.${selectedGradeBand.id}.${cluster}`, {
				params: {
					programId,
					roleCode,
				},
			})
				.then((res) => {
					if (res.status === 200) {
						const lls = [];
						res.data.neighborhood.linkageLevels.forEach((ll) => {
							if (!lls.includes(ll.linkageLevelName)) {
								lls.push(ll.linkageLevelName);
							}
						});
						// sort levels
						lls.sort();
						setLevels(lls);
						if (!lls.includes(selectedLevel)) {
							setSelectedLevel(null);
						}
					} else {
						// Something broke. Zero everything out.
						setLevels([]);
						setSelectedLevel(null);
					}
				})
				.catch(() => {
					// Something broke. Zero everything out.
					setLevels([]);
					setSelectedLevel(null);
				});
		}
	// eslint-disable-next-line max-len
	}, [cluster, mapAssignment.map_ee, onSetWritingLevel, programId, roleCode, selectedGradeBand, selectedLevel, selectedWritingLevel, suggestedVal]);

	const submitMaps = () => {
		axios.put(`/api/cycle/${cycleId}/map`, {
			writingLevel: cluster,
			clusterLevelName: selectedLevel,
			mapAssignmentId: mapAssignment.id,
			gradeBand: selectedGradeBand.id,
		}, {
			params: {
				programId,
				roleCode,
				userId,
			},
		}).then((res) => {
			if (res.status === 200) {
				// Hooray! Force the parent to reload so we can move to the next step!
				reload(cycleId);
			}
		});
	};

	// Get cluster levels from db
	useEffect(() => {
		// eslint-disable-next-line max-len
		updateComboBoxes(mapAssignment.map_ee, selectedGradeBand, suggestedVal, programId, roleCode, selectedLevel, cluster);
	// eslint-disable-next-line max-len
	}, [mapAssignment.map_ee, selectedGradeBand, suggestedVal, programId, roleCode, selectedLevel, cluster, updateComboBoxes]);

	const readOnly = !(permissions && permissions.filter((perm) => perm.code === 'UI_MAP_ASSIGNMENT').length);

	return (
		<div className="map-assignment-container">
			<div>
				<span>Suggested Cluster:</span>
				<span>{suggestedVal}</span>
			</div>
			<div>
				<h4>
					If you would like to change your cluster (Writing Level),
					Grade Band, and Cluster Level use the drop-down menus to reassign.

				</h4>
			</div>
			<ComboBox
				style={{ width: '30%' }}
				disabled={readOnly}
				label="Writing Level"
				name="grade-band"
				data={writingLevels}
				required={true}
				value={selectedWritingLevel}
				textField="label"
				dataItemKey="id"
				onChange={(e) => setSelectedWritingLevel(e.value)}
			/>
			<ComboBox
				style={{ width: '30%' }}
				disabled={readOnly}
				label="Grade Band"
				name="grade-band"
				data={gradeBands}
				required={true}
				value={selectedGradeBand}
				textField="label"
				dataItemKey="id"
				onChange={(e) => setSelectedGradeBand(e.value)}
			/>
			<ComboBox
				style={{ width: '30%' }}
				disabled={!levels.length || readOnly}
				label="Cluster Level"
				name="level"
				data={levels}
				required={true}
				value={selectedLevel}
				onChange={(e) => setSelectedLevel(e.value)}
			/>
			{
				readOnly ? null : (
					<button
						type="button"
						className="btn btn-primary"
						disabled={!selectedLevel}
						primary={true}
						onClick={submitMaps}
					>
						Assign
					</button>
				)
			}
		</div>
	);
};
export default connect(mapStateToProps)(MapAssignment);
