import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { useImmer } from 'use-immer';
import CanvasPage from './LearningMaps/CanvasPage';
import './StudentMaps.css';
import ClusterMediaGrid from './ClusterMediaGrid';

const mapStateToProps = (state) => {
	const { authReducer } = state;
	return {
		programId: authReducer.assessmentProgram.id,
		roleCode: authReducer.role.code,
		permissions: authReducer.role.permission,
		userId: authReducer.userObj.id,
	};
};

const StudentMaps = (props) => {
	// ///////////////////
	// STATES AND PROPS //
	// ///////////////////
	const {
		mapAssignment,
		programId,
		roleCode,
		permissions,
		userId,
		cycleId,
		reload,
		historical,
		suggestedVal,
		passedWritingLevel,
	} = props;
	const [renderData, updateRenderData] = useImmer();
	const [fullMapData, setFullMapData] = useState();
	const [filteredMapData, setFilteredMapData] = useState();
	const [cluster, setCluster] = useState();
	const [filtered, setFiltered] = useState(false);

	// //////////////////////////
	// TOGGLING LINKAGE LEVELS //
	// //////////////////////////
	function handleLinkageButtonClick() {
		setFiltered((filter) => (!filter));
		updateRenderData(null);
	}
	// ////////////////////
	// UNASSIGN CLUSTERS //
	// ////////////////////
	const unassignClusterLevel = () => {
		axios.put(`/api/cycle/${cycleId}/map`, {
			clusterName: mapAssignment.map_ee,
			clusterLevelName: null,
			mapAssignmentId: mapAssignment.id,
			writingLevel: cluster,
		}, {
			params: {
				programId,
				roleCode,
				userId,
			},
		}).then((res) => {
			if (res.status === 200) {
				// Force the parent to reload so we can move to the next step!
				reload(cycleId);
			}
		});
	};

	// ////////////////////
	// Get Map Data //
	// ////////////////////
	const filterMapData = useCallback((res) => {
		if (res.status === 200) {
			setFullMapData(res.data);
			const newFilteredMap = cloneDeep(res.data);
			let newnodes = [];
			newFilteredMap.neighborhood.linkageLevels.forEach((linkageLevel) => {
				if (linkageLevel.linkageLevelName === mapAssignment.cluster_level) {
					newnodes = linkageLevel.nodes.map((node) => (node.nodeKey));
				}
			});
			newFilteredMap.neighborhood.nodes = newFilteredMap.neighborhood.nodes
				.filter((node) => (newnodes.includes((node.nodeKey))));
			newFilteredMap.neighborhood.connections = newFilteredMap.neighborhood.connections
				.filter((connection) => (newnodes.includes(connection.sourceNodeKey)
	&& newnodes.includes(connection.destinationNodeKey)));
			newFilteredMap.neighborhood.linkageLevels = [];
			setFilteredMapData(newFilteredMap);
		} else {
			setFullMapData(null);
			setFilteredMapData(null);
		}
	}, [mapAssignment.cluster_level]);

	const canUnassign = (permissions && permissions.filter((perm) => perm.code === 'UI_MAP_ASSIGNMENT').length && !historical);
	// //////////////////////////
	// GATHER AND SET MAP INFO //
	// //////////////////////////
	useEffect(() => {
		if (!passedWritingLevel) {
			if (suggestedVal === 'Conventional') {
				setCluster('C');
			} else if (suggestedVal === 'Transitional') {
				setCluster('T');
			} else if (suggestedVal === 'Emergent') {
				setCluster('E');
			} else {
				setCluster('C');
			}
		} else {
			setCluster(passedWritingLevel.id);
		}
		if (mapAssignment.cluster) {
			axios.get(`/api/map/data/${mapAssignment.map_ee}.${mapAssignment.grade_band}.${mapAssignment.cluster}`, {
				params: {
					programId,
					roleCode,
				},
			})
				.then((res) => {
					filterMapData(res);
				})
				.catch(() => {
					setFullMapData(null);
					setFilteredMapData(null);
				});
		} else if (cluster) {
			axios.get(`/api/map/data/${mapAssignment.map_ee}.${mapAssignment.grade_band}.${cluster}`, {
				params: {
					programId,
					roleCode,
				},
			})
				.then((res) => {
					filterMapData(res);
				})
				.catch(() => {
					setFullMapData(null);
					setFilteredMapData(null);
				});
		}

	// eslint-disable-next-line max-len
	}, [mapAssignment.map_ee, mapAssignment.grade_band, mapAssignment.cluster_level, programId, roleCode, cluster, passedWritingLevel, mapAssignment.cluster, suggestedVal, filterMapData]);

	useEffect(() => {
		if (!filtered) {
			updateRenderData(fullMapData);
		} else {
			updateRenderData(filteredMapData);
		}
	}, [filtered, filteredMapData, fullMapData, updateRenderData]);
	// Loading Message for Slow Load
	if (!renderData) {
		return 'Loading. Please wait.';
	}
	// /////////
	// RETURN //
	// /////////
	return (
		<div className="student-maps-container">
			<div>
				<div className="student-map-button-instructions">
					<h4>
						The Current Target View button allows you to change views
						between all three levels in the cluster and the specific cluster
						assigned to your student.
					</h4>
				</div>
				<div className="student-map-button-instructions">
					<h4>
						The Change Writing Level, Grade Band or Cluster Level button
						allows you to go back to the previous screen to change
						the writing level, grade band or cluster level.
					</h4>
				</div>
			</div>
			<button
				type="button"
				className="btn btn-primary"
				primary={true}
				onClick={handleLinkageButtonClick}
			>
				{/* CHANGE LABEL BACK */}
				{ filtered ? 'Expand View' : 'Current Target View' }
			</button>
			{
				canUnassign ? (
					<button
						type="button"
						className="btn btn-primary"
						primary={true}
						onClick={unassignClusterLevel}
					>
						Change Writing Level, Grade Band or Cluster Level
					</button>
				) : null
			}
			<div className="col-12">
				<CanvasPage
					mapData={renderData}
				/>
			</div>
			<ClusterMediaGrid
				mapAssignment={mapAssignment}
				cluster={cluster}
				programId={programId}
				roleCode={roleCode}
			/>
		</div>
	);
};
export default connect(mapStateToProps)(StudentMaps);
