import { combineReducers } from 'redux';
import authReducer from '../auth/AuthReducer';
import memberReducer from './MemberReducer';

/**
 * @summary
 * This combinedReducers holds all the reducers used in the application.
 */
const rootReducer = combineReducers({
	authReducer,
	memberReducer,
});

export default rootReducer;
