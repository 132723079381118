import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist'; // redux persist to persist user login data
import storageSession from 'redux-persist/lib/storage/session'; // storage module for redux persist (session storage)
import rootReducer from './RootReducer';

// Redux persist config object
const persistConfig = {
	key: 'root',
	storage: storageSession,
	whiteList: ['authReducer'], // Array of reducers to be persisted
};
// Creates a redux persist reducer from the root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

/**
 * @summary
 * Created common redux-store.
 * This same store will be used for entire application as a state container.
 * @param {combinedReducer} rootReducer
 * @param {redux-middleware} composeWithDevTools
 */
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(logger, thunk)));
// Creates a persisted version of the redux store
export const persistor = persistStore(store);

export default store;
