/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { connect, useDispatch } from 'react-redux';
import axios from 'axios';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
	setAssessmentProgram, setRole, receiveSignOut, signOutError,
} from '../auth/AuthActions';

const mapStateToProps = (state) => {
	const { authReducer } = state;
	return {
		userObj: authReducer.userObj,
		currentAp: authReducer.assessmentProgram,
		currentRole: authReducer.role,
	};
};

const RoleSelect = ({ userObj, currentRole, currentAp }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [show, setShow] = useState(false);

	const displayName = userObj.displayName
		? userObj.displayName
		: `${userObj.firstName} ${userObj.lastName}`;

	const handleSignOut = () => {
		axios
			.post('/api/signOut', {
				email: userObj.emailAddress,
			})
			.then((res) => {
				const statusCode = res.status;

				if (statusCode === 200) {
					// redirect to the login page
					dispatch(receiveSignOut(res.data));
					history.push('/login');
				} else {
					dispatch(signOutError('Sign out recieved status other than 200 but was not caught.'));
				}
			});
	};

	const onModalChange = () => {
		setShow(!show);
	};

	return (
	// <div>
	// 	<div>
	// 		<span>Program </span>
	// 		<DropDownList
	// 			data={userObj.assessmentPrograms}
	// 			value={currentAp}
	// 			style={{ width: '275px' }}
	// 			textField="name"
	// 			dataItemKey="id"
	// 			// defaultItem={{ id: null, name: 'Select' }}
	// 			onChange={(e) => dispatch(setAssessmentProgram(e.target.value))}
	// 		/>
	// 	</div>
	// 	<div>
	// 		<span>Role </span>
	// 		<DropDownList
	// 			data={currentAp.roles}
	// 			value={currentRole}
	// 			style={{ width: '275px' }}
	// 			textField="description"
	// 			dataItemKey="code"
	// 			// defaultItem={{ id: null, name: 'Select' }}
	// 			onChange={(e) => dispatch(setRole(e.target.value))}
	// 		/>
	// 	</div>
	// </div>

		<div className="float-end">
			<div
				variant="primary"
				onClick={() => onModalChange()}
				className="nav-link cursor-pointer alignLeft"
				aria-hidden="true"
			>
				Signed in as
				{' '}
				{ displayName }
				<i className="bi bi-person-circle ms-2 me-1" />
				<span
					className={
						show ? 'k-icon k-i-arrow-60-down' : 'k-icon k-i-arrow-60-left'
					}
				/>
			</div>
			<div className="nav-link alignRight">
				<span>
					<Link to="/notifications">
						<span className="k-icon k-i-notification" />
					</Link>
				</span>
			</div>
			<Offcanvas
				show={show}
				placement="end"
				scroll={false}
				backdrop={false}
				className="userProfile"
			>
				<Offcanvas.Header>
					<Offcanvas.Title>Current Settings</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<fieldset className="bg-light p-3 rounded">
						<div className="col d-flex p-2 justify-content-between">
							<div className="col-5 text-end">
								<label className="form-label">Program:</label>
							</div>
							<div className="col-7">
								<select
									className="form-select form-select-sm"
									onChange={(e) => dispatch(
										setAssessmentProgram(
											userObj.assessmentPrograms.find(
												(ap) => (ap.id === parseInt(e.target.value, 10)),
											),
										),
									)}
									value={currentAp.id}
								>
									{userObj.assessmentPrograms.map((program) => (
										<option key={program.id} value={program.id}>
											{program.name}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="col d-flex p-2 justify-content-between">
							<div className="col-5 text-end">
								<label className="form-label">Role:</label>
							</div>
							<div className="col-7">

								<select
									className="form-select form-select-sm"
									onChange={(e) => {
										dispatch(
											setRole(currentAp.roles.find(
												(role) => (role.id === parseInt(e.target.value, 10)),
											)),
										);
									}}
									value={currentRole.id}
								>
									{currentAp.roles.map((role) => (
										<option key={role.id} value={role.id}>
											{role.name}
										</option>
									))}
								</select>

							</div>
						</div>
					</fieldset>
					<div className="col pt-1 pb-1 hstack gap-2" />

					<div className="d-grid d-md-flex justify-content-between mt-2">
						<button
							className="btn btn-primary me-md-2"
							type="button"
							onClick={handleSignOut}
						>
							<i className="bi bi-box-arrow-right" />
							{' '}
							Log Out
						</button>

					</div>
				</Offcanvas.Body>
			</Offcanvas>
		</div>
	);
};

export default connect(mapStateToProps)(RoleSelect);
