export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNOUT_FAILURE = 'SIGNOUT_FAILURE';
export const FORGOT_PWD_FAILURE = 'FORGOT_PWD_FAILURE';
export const RESET_PWD_FAILURE = 'RESET_PWD_FAILURE';
export const FORGOT_PWD_SUCCESS = 'FORGOT_PWD_SUCCESS';
export const RESET_PWD_SUCCESS = 'RESET_PWD_SUCCESS';
export const ASSESSMENT_PROGRAM_SET = 'ASSESSMENT_ROGRAM_SET';
export const ROLE_SET = 'ROLE_SET';
export const NEW_PWD_FAILURE = 'NEW_PWD_FAILURE';
export const NEW_PWD_SUCCESS = 'NEW_PWD_SUCCESS';
