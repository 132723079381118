import getMembers from '../store/MemberActions';
import {
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
	SIGNOUT_SUCCESS,
	SIGNOUT_FAILURE,
	FORGOT_PWD_FAILURE,
	RESET_PWD_FAILURE,
	ASSESSMENT_PROGRAM_SET,
	ROLE_SET,
	NEW_PWD_FAILURE,
	NEW_PWD_SUCCESS,
} from './AuthTypes';

export const setAssessmentProgram = (ap) => async (dispatch) => {
	dispatch({
		type: ASSESSMENT_PROGRAM_SET,
		assessmentProgram: ap,
	});
	dispatch(getMembers());
};

export const setRole = (role) => {
	return {
		type: ROLE_SET,
		role,
	};
};

export const receiveLogin = (user) => async (dispatch) => {
	dispatch({
		type: LOGIN_SUCCESS,
		isAuthenticated: true,
		userObj: user,
		errorMessage: null,
	});
	dispatch(getMembers());
};

export const loginError = (message) => {
	return {
		type: LOGIN_FAILURE,
		isAuthenticated: false,
		userObj: {},
		errorMessage: message,
	};
};

export const forgotPasswordError = (message) => {
	return {
		type: FORGOT_PWD_FAILURE,
		isAuthenticated: false,
		userObj: {},
		errorMessage: message,
	};
};

export const resetPasswordError = (message) => {
	return {
		type: RESET_PWD_FAILURE,
		isAuthenticated: false,
		userObj: {},
		errorMessage: message,
	};
};

export const receiveSignOut = () => {
	return {
		type: SIGNOUT_SUCCESS,
		isAuthenticated: false,
		userObj: {},
		errorMessage: null,
	};
};

export const signOutError = (message) => {
	return {
		type: SIGNOUT_FAILURE,
		isAuthenticated: true,
		userObj: {},
		errorMessage: message,
	};
};

export const receiveNewPassword = (user) => {
	return {
		type: NEW_PWD_SUCCESS,
		isAuthenticated: false,
		userObj: user,
	};
};

export const newPasswordError = (message) => {
	return {
		type: NEW_PWD_FAILURE,
		isAuthenticated: false,
		userObj: {},
		errorMessage: message,
	};
};
