import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
	Route,
	Switch,
	useHistory,
} from 'react-router-dom';
import {
	LogIn, ForgotPassword, ResetPassword, NewPassword,
} from 'ats-react-login';
import axios from 'axios';
import {
	receiveLogin,
	receiveSignOut,
	loginError,
	forgotPasswordError,
	resetPasswordError,
	newPasswordError,
} from './auth/AuthActions';
import Rosters from './rosters/Rosters';
import Teachers from './teachers/Teachers';
import AppHeader from './ui/AppHeader';
import Home from './home/Home';
import RosterUpload from './rosters/RosterUpload';
import FilteringNotifications from './notifications/FilteringNotifications';
import Resources from './resources/Resources';
import './App.css';

const mapStateToProps = (state) => {
	const { authReducer } = state;
	return {
		isAuthenticated: authReducer.isAuthenticated,
		permissions: authReducer.role?.permission,
		errorMessage: authReducer.errorMessage,
	};
};

const App = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { isAuthenticated, permissions, errorMessage } = props;

	// Adds a session storage variable to check for reloads
	useEffect(() => {
		sessionStorage.reload = true;
	}, []);

	// force redirect to home page if back button is used
	history.listen(() => {
		if (history.action === 'POP') {
			history.push('/');
		}
	});

	// Redirects to home page on browser reload or close/open
	if (sessionStorage.reload && history.location.pathname !== '/') {
		sessionStorage.reload = '';
		history.push('/');
	}

	const menuItems = [{ text: 'Home', data: { route: '/' } }];

	// Populate appropriate navigation headers
	if (isAuthenticated) {
		if (permissions.some((perm) => perm.code === 'READ_ROSTER')) {
			menuItems.push({ text: 'Classes', data: { route: '/rosters' } });
		}
		if (permissions.some((perm) => perm.code === 'READ_TEACHERS')) {
			menuItems.push({ text: 'Teachers', data: { route: '/teachers' } });
		}
		if (permissions.some((perm) => perm.code === 'READ_RESOURCE')) {
			menuItems.push({ text: 'Resources', data: { route: '/resources' } });
		}
	}

	let heading = (
		<AppHeader
			menuItems={menuItems}
		/>
	);
	// If we're not authenticated, start on the login page.
	if (!isAuthenticated) {
		history.push('/login');
		heading = null;
	}

	return (
		<div className="App">
			{heading}
			<Switch>
				<Route
					path="/login"
					render={() => (
						<LogIn
							resetLink={(
								<button
									type="button"
									className="btn btn-primary"
									onClick={(e) => {
										e.preventDefault();
										// for some reason history and dispatch are not working together properly.
										// need some help figuring this out.
										history.push('/forgotPassword');
										if (errorMessage) {
											dispatch(receiveSignOut());
										}
									}}
								>
									Forgot Password?
								</button>
							)}
							logo={`${process.env.PUBLIC_URL}/kite_logo.svg`}
							background={`${process.env.PUBLIC_URL}/Kite_Login_BG.png`}
							errorMessage={errorMessage}
							onSubmit={(event, email, password) => {
								event.preventDefault();
								axios
									.post('/api/validateUser', {
										email,
										password,
									})
									.then((res) => {
										const statusCode = res.status;
										if (statusCode === 200) {
											if (res.data.reason && res.data.reason === 'newPasswordRequired') {
												// handle newPasswordRequired pathway
												history.push({
													pathname: '/newPassword',
													data: { email, oldPassword: res.data.oldPassword },
												});
											} else {
												// redirect to the home page
												dispatch(receiveLogin(res.data));
												history.push('/');
											}
										} else {
											dispatch(loginError('Login recieved status other than 200 but was not caught.'));
										}
									})
									.catch((error) => {
										dispatch(loginError(error.response.data));
									});
							}}
						/>
					)}
				/>
				<Route
					path="/forgotPassword"
					render={() => (
						<ForgotPassword
							logo={`${process.env.PUBLIC_URL}/kite_logo.svg`}
							errorMessage={errorMessage}
							onSubmit={(event, email) => {
								event.preventDefault();
								axios
									.post('/api/requestPasswordReset', {
										email,
									})
									.then((res) => {
										const statusCode = res.status;
										if (statusCode === 200) {
											// redirect to the reset password page
											// add the email to the location for access on the reset password page
											history.push({
												pathname: '/resetPassword',
												data: { email },
											});
										} else {
											dispatch(forgotPasswordError('Forgot Password recieved status other than 200 but was not caught.'));
											history.push('/forgotPassword');
										}
									})
									.catch((error) => {
										dispatch(forgotPasswordError(error.response.data));
										history.push('/forgotPassword');
									});
							}}
						/>
					)}
				/>
				<Route
					path="/resetPassword"
					render={() => (
						<ResetPassword
							logo={`${process.env.PUBLIC_URL}/kite_logo.svg`}
							errorMessage={errorMessage}
							onSubmit={(event, verificationCode, newPassword) => {
								event.preventDefault();
								// get email entered on the forgot password page
								const { email } = history.location.data;
								axios
									.post('/api/passwordReset', {
										email,
										verificationCode,
										newPassword,
									})
									.then((res) => {
										const statusCode = res.status;
										if (statusCode === 200) {
											// redirect to the login page
											history.push('/login');
										} else {
											dispatch(resetPasswordError('Reset Password recieved status other than 200 but was not caught.'));
											history.push('/resetPassword');
										}
									})
									.catch((error) => {
										dispatch(resetPasswordError(error.response.data));
										history.push('/resetPassword');
									});
							}}
						/>
					)}
				/>
				<Route
					path="/newPassword"
					render={() => {
						return (
							<NewPassword
								logo={`${process.env.PUBLIC_URL}/kite_logo.svg`}
								errorMessage={errorMessage}
								onSubmit={(e, newPassword) => {
									e.preventDefault();
									const { email, oldPassword } = history.location.data;
									axios.post('/api/newPassword', {
										userAttributes: { email },
										oldPassword,
										newPassword,
									})
										.then((resp) => {
											const statusCode = resp.status;
											if (statusCode === 200) {
												history.push('/login');
											} else {
												dispatch(newPasswordError('New Password recieved status other than 200 but was not caught.'));
												history.push('/newPassword');
											}
										})
										.catch((err) => {
											dispatch(newPasswordError(err.response.data));
											history.push('/newPassword');
										});
								}}
							/>
						);
					}}
				/>
				<Route
					exact
					path="/"
					render={() => (
						<Home isAuthenticated={isAuthenticated} />
					)}
				/>
				<Route
					path="/rosters"
					component={Rosters}
				/>
				<Route
					path="/rosterupload"
					component={RosterUpload}
				/>
				<Route
					path="/teachers"
					component={Teachers}
				/>
				<Route
					path="/resources"
					component={Resources}
				/>
				<Route
					path="/notifications"
					render={() => (
						<FilteringNotifications isAuthenticated={isAuthenticated} />
					)}
				/>
			</Switch>
		</div>
	);
};
export default connect(mapStateToProps)(App);
