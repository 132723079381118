/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import axios from 'axios';
import CKEditor from '../shared/CKEditor';
import DeleteModal from '../modals/DeleteModal';

const mapStateToProps = (state) => {
	const { authReducer } = state;
	return {
		programId: authReducer.assessmentProgram.id,
		roleCode: authReducer.role.code,
	};
};

const StudentNotes = (props) => {
	const [gridData, setGridData] = useState([]);
	const [noteModalVisible, setNoteModalVisible] = useState(false);
	const [noteModalContents, setNoteModalContents] = useState('');
	const [noteModalId, setNoteModalId] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [item, setItem] = useState({});

	const { cycleId, programId, roleCode } = props;

	// Update the contents of the grid from the server (run after any data actions)
	const getGridData = useCallback(() => {
		axios.get(`/api/note/cycle/${cycleId}`, {
			params: {
				programId,
				roleCode,
			},
		})
			.then((result) => {
				setGridData(result.data);
			})
			.catch(() => {
				// Something went wrong. Zero everything out!
				setGridData([]);
			});
	}, [setGridData, cycleId, programId, roleCode]);

	// Expire the note on the server when the trashcan icon is pressed
	const deleteNote = useCallback((dataItem) => {
		axios.put(`/api/note/expire/${dataItem.id}`, {}, {
			params: {
				programId,
				roleCode,
			},
		})
			.then(() => {
				getGridData();
			});
	}, [getGridData, programId, roleCode]);

	// On modal close, zero out everything related to it
	const closeNoteModal = () => {
		setNoteModalVisible(false);
		setNoteModalContents('');
		setNoteModalId(null);
	};

	// Send the new note info to the server, close the modal, and have the grid reload
	const saveNote = () => {
		const axiosParams = {
			url: `/api/note/${cycleId}`,
			data: {
				contents: noteModalContents,
			},
			params: {
				programId,
				roleCode,
			},
		};

		if (noteModalId) {
			// Editing an existing note
			axiosParams.method = 'put';
			axiosParams.data.id = noteModalId;
		} else {
			axiosParams.method = 'post';
			// Creating a new note
		}

		axios(axiosParams).then(() => {
			closeNoteModal();
			getGridData();
		});
	};

	const onChange = (fieldName, value) => {
		setNoteModalContents(value);
	};

	// On component load, get data from the server
	useEffect(() => {
		getGridData();
	}, [getGridData]);

	return (
		<div>
			<Grid
				data={gridData}
			>
				<Column
					field="created_date"
					title="Date"
					cell={(data) => (
						<td>
							{new Date(data.dataItem.created_date).toDateString()}
						</td>
					)}
				/>
				<Column
					field="contents"
					title="Note"
					cell={(data) => (
						// Linter disable justification:
						// CKEditor produces HTML. There's no real way to display it except dangerously.
						// eslint-disable-next-line react/no-danger
						<td dangerouslySetInnerHTML={{ __html: data.dataItem.contents }} />
					)}
				/>
				<Column cell={(data) => (
					<td>
						<button
							type="button"
							className="btn btn-primary m-1 p-1"
							icon="edit"
							onClick={() => {
								setNoteModalContents(data.dataItem.contents);
								setNoteModalId(data.dataItem.id);
								setNoteModalVisible(true);
							}}
						>
							Edit Note
						</button>
						<button
							type="button"
							className="btn btn-primary bi bi-trash  m-1 p-1"
							icon="delete"
							onClick={() => {
								setShowModal(!showModal);
								setItem(data);
							}}
						>
							Delete Note
						</button>
					</td>
				)}
				/>
			</Grid>
			<button
				type="button"
				className="btn btn-primary"
				primary={true}
				onClick={() => setNoteModalVisible(true)}
			>
				+ Add Note
			</button>
			{ // This modal code will only display when the user is creating or editing a note
				noteModalVisible
				&& (
					<Dialog
						title="Write New Note"
						onClose={closeNoteModal}
					>
						<CKEditor
							data={noteModalContents}
							onChange={onChange}
						/>
						<DialogActionsBar>
							<button
								type="button"
								className="btn btn-primary"
								onClick={closeNoteModal}
							>
								Cancel
							</button>
							<button
								type="button"
								className="btn btn-primary"
								primary={true}
								onClick={saveNote}
							>
								Save
							</button>
						</DialogActionsBar>
					</Dialog>
				)
			}
			<DeleteModal
				show={showModal}
				setShow={setShowModal}
				deleteHandler={deleteNote}
				item={item}
			/>
		</div>
	);
};
export default connect(mapStateToProps)(StudentNotes);
