import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { ListView } from '@progress/kendo-react-listview';
import { Checkbox } from '@progress/kendo-react-inputs';
import axios from 'axios';
import NotificationItem from './NotificationItem';
import './FilteringNotifications.css';

const mapStateToProps = (state) => {
	const { authReducer } = state;
	return {
		programId: authReducer.assessmentProgram.id,
		roleCode: authReducer.role.code,
	};
};

const FilteringNotifications = (props) => {
	const history = useHistory();
	const { programId, roleCode, isAuthenticated } = props;
	const [gridData, setGridData] = useState([]);
	const [filteredGridData, setFilteredGridData] = useState([]);
	const [selectedStudents, setSelectedStudents] = useState([]);
	const [notifiedStudents, setNotifiedStudents] = useState([]);

	// If user is not authenticated, start on the login page.
	if (!isAuthenticated) {
		history.push('/login');
	}

	const fetchNotifications = useCallback(() => {
		axios.get('/api/notification/all', {
			params: {
				programId,
				roleCode,
			},
		})
			.then((res) => {
				if (res.status === 200 && res.data.length) {
					setGridData(res.data);
				} else {
					setGridData([]);
				}
			})
			.catch(() => {
				setGridData([]);
			});
	}, [setGridData, programId, roleCode]);

	useEffect(() => {
		fetchNotifications();
	}, [setGridData, fetchNotifications]);

	useEffect(() => {
		setNotifiedStudents(gridData
			.map((item) => ({
				id: item.student_id,
				fullName: item.studentFullName,
				firstName: item.student_legal_first_name,
				middleName: item.studnet_legal_middle_name,
				lastName: item.student_legal_last_name,
				generationCode: item.generation_code,
			}))
			.reduce((accumulator, currentValue) => {
				if (accumulator.findIndex((student) => {
					return student.id === currentValue.id;
				}) === -1) {
					accumulator.push(currentValue);
				}
				return accumulator;
			}, [])
			.sort((a, b) => {
				if (a.lastName < b.lastName) {
					return -1;
				}
				if (a.lastName > b.lastName) {
					return 1;
				}
				if (a.firstName < b.firstName) {
					return -1;
				}
				if (a.firstName > b.firstName) {
					return 1;
				}
				if (a.middleName < b.middleName) {
					return -1;
				}
				if (a.middleName > b.middleName) {
					return 1;
				}
				return 0;
			}));
	}, [setNotifiedStudents, gridData]);

	useEffect(() => {
		setSelectedStudents((oldSelectedStudents) => {
			// Set selected students to notified students unless not all students were selected before.
			if (oldSelectedStudents.length === 0) {
				return [...notifiedStudents];
			}
			if (notifiedStudents.some((notifiedStudent) => {
				if (oldSelectedStudents.some((selectedStudent) => {
					return notifiedStudent.id === selectedStudent.id;
				})) { // at least one old selected student that matches the current notified student
					return true;
				}
				return false;
			})) {
				// at least one student in old selected isn't in notified
				// make sure to remove any students that are no longer in notified students.
				return notifiedStudents.filter((notifiedStudent) => {
					return oldSelectedStudents.some((selectedStudent) => {
						return selectedStudent.id === notifiedStudent.id;
					});
				});
			}
			return [...notifiedStudents];
			// If not all students were selected before,
		});
	}, [setSelectedStudents, notifiedStudents]);

	useEffect(() => {
		setFilteredGridData(
			gridData.filter(
				(item) => (
					selectedStudents.find((student) => (item.student_id === student.id)) !== undefined
				),
			),
		);
	}, [setFilteredGridData, gridData, selectedStudents]);

	const handleFilterStudentCheck = (id, event) => {
		const checkedStudent = notifiedStudents.find((item) => item.id === id);
		if (event.value) {
			// checked
			setSelectedStudents((oldArray) => [...oldArray, checkedStudent]);
		} else {
			setSelectedStudents((oldArray) => oldArray.filter((item) => item.id !== checkedStudent.id));
		}
	};

	const handleClearFilterClick = () => {
		setSelectedStudents([]);
	};

	const handleSetAllFilterClick = () => {
		setSelectedStudents([...notifiedStudents]);
	};

	return (
		<div id="filternotifications">
			<h1>Notifications</h1>
			<div id="filternotificationsContent">
				<div id="filters">
					<h2>Filter by:</h2>
					<div>
						{notifiedStudents.map((student) => (
							<div key={student.id}>
								<Checkbox
									checked={selectedStudents.find((item) => item.id === student.id) !== undefined}
									label={student.fullName}
									onChange={(e) => handleFilterStudentCheck(student.id, e)}
								/>
							</div>
						))}
					</div>
					<button
						type="button"
						className="btn btn-light"
						onClick={handleClearFilterClick}
					>
						Clear Filters
					</button>
					<button
						type="button"
						className="btn btn-light"
						onClick={handleSetAllFilterClick}
					>
						Reset Filters
					</button>
				</div>
				<div id="notifications">
					<ListView
						style={{ height: '300px' }}
						data={filteredGridData}
						item={(innerProps) => (
							<NotificationItem
								dataItem={innerProps.dataItem}
								markReadCallback={() => { fetchNotifications(); }}
							/>
						)}
					/>
				</div>
			</div>
		</div>
	);
};
export default connect(mapStateToProps)(FilteringNotifications);
