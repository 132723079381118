import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './canvasPage.css';
import Canvas from './Canvas';
import formatMapData from './utils/formatMapData';

const CanvasPage = ({
	mapData,
}) => {
	/// ///////////////////////////
	// FORMATTING DATA FOR GOJS //
	/// ///////////////////////////
	const diagramData = formatMapData(mapData);

	return (
		<div className="col-12">
			{/* ///////////////////////////// */}
			{/* ///// CANVAS COMPONENT ////// */}
			{/* ///////////////////////////// */}
			<Canvas
				nodeDataArray={diagramData.nodeDataArray}
				linkDataArray={diagramData.linkDataArray}
				modelData={diagramData.modelData}
				// selectLinkageLevel={selectLinkageLevel}
				skipsDiagramUpdate={false}
			/>
		</div>
	);
};

export default CanvasPage;
