import React from 'react';
import { Error } from '@progress/kendo-react-labels';

const FilterMessages = (props) => {
	const { messages, fieldkey, innerId } = props;

	const filteredMessages = messages.filter((message) => message.field === fieldkey);

	let content = null;
	if (filteredMessages.length) {
		if (innerId) {
			content = (<Error id={innerId}>{filteredMessages[0].message}</Error>);
		} else {
			content = (
				<ul>
					{	filteredMessages.map((message) => (
						<li key={message}>
							{message.message}
						</li>
					))}
				</ul>
			);
		}
	}
	return content;
};

export default FilterMessages;
