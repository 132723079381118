import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ListView } from '@progress/kendo-react-listview';
import axios from 'axios';
import './Notifications.css';
import NotificationItem from './NotificationItem';

const mapStateToProps = (state) => {
	const { authReducer } = state;
	return {
		programId: authReducer.assessmentProgram.id,
		roleCode: authReducer.role.code,
	};
};

const Notifications = (props) => {
	const { numToDisplay, programId, roleCode } = props;
	const [gridData, setGridData] = useState([]);

	const fetchNotifications = useCallback(() => {
		axios.get('/api/notification/all', {
			params: {
				programId,
				roleCode,
				limit: numToDisplay,
			},
		})
			.then((res) => {
				if (res.status === 200 && res.data.length) {
					setGridData(res.data);
				} else {
					setGridData([]);
				}
			})
			.catch(() => {
				setGridData([]);
			});
	}, [setGridData, numToDisplay, programId, roleCode]);

	useEffect(() => {
		fetchNotifications();
	}, [setGridData, numToDisplay, fetchNotifications, programId, roleCode]);

	return (
		<div id="notifications">
			<h2>Notifications</h2>
			{
				gridData.length ? (
					<ListView
						data={gridData}
						item={(innerProps) => (
							<NotificationItem
								dataItem={innerProps.dataItem}
								markReadCallback={() => { fetchNotifications(); }}
							/>
						)}
					/>
				) : 'You have no notifications at this time'
			}

		</div>
	);
};

export default connect(mapStateToProps)(Notifications);
